import { makeAutoObservable } from 'mobx';
import { Liedeo } from '../../types';

export class PlayerStore {

    public isPlayingVideo = false;
    public isPlayingMusic = false;
    public isVideoReady = false;
    public isMusicReady = false;
    public liedeo?: Liedeo;

    constructor() {
        makeAutoObservable(this)
    }

    public get isReady(): boolean {
        return this.isVideoReady && this.isMusicReady;
    }

    public get isPlaying(): boolean {
        return this.isPlayingVideo || this.isPlayingMusic;
    }

    public setLiedeo(liedeo: Liedeo): void {
        this.liedeo = liedeo;
    }

    public setIsVideoReady(isReady: boolean): void {
        this.isVideoReady = isReady;
    }

    public setIsMusicReady(isReady: boolean): void {
        this.isMusicReady = isReady;
    }

    public playVideo(): void {
        this.isPlayingVideo = true;
    }

    public playMusic(): void {
        this.isPlayingMusic = true;
    }

    public pauseVideo(): void {
        this.isPlayingVideo = false;
    }

    public pauseMusic(): void {
        this.isPlayingMusic = false;
    }
}
