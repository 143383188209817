import { autorun, makeAutoObservable, toJS } from 'mobx';
import { Liedeo, LiedeoHost, LiedeoSave } from '../../types';
import { ChangeEvent } from 'react';
import { UserStore } from '../../data/stores/user-store';
import { youtubeUrlToId } from '../../util/youtube-url-to-id-extractor';
import { LiedeoStore } from '../../data/stores/liedeo-store';

export class CreatorStore {

    private readonly LOCAL_STORAGE_KEY: string = 'creatorStore';

    private userStore?: UserStore;
    private liedeoStore: LiedeoStore;
    public playing = false;
    public title = '';
    public videoHost: LiedeoHost = 'YOUTUBE';
    public videoUrl = '';
    public videoUrlValidationActive = false;
    public videoOffsetMillis = 0;
    public musicUrl = '';
    public musicUrlValidationActive = false;
    public musicHost: LiedeoHost = 'YOUTUBE';
    public musicOffsetMillis = 0;

    public constructor(userStore: UserStore, liedeoStore: LiedeoStore) {
        this.userStore = userStore;
        this.liedeoStore = liedeoStore;

        makeAutoObservable(this);

        this.setupFromLocalStorage();

        autorun((): void => {
            this.persistToLocalStorage();
        })
    }

    public get errorMessage(): string {
        if (this.userStore && !this.userStore.hasUsername) {
            return 'Error: Please set your username first.';
        } else {
            if (!this.validInput) {
                return 'Error: Please fill out all fields.';
            } else {
                return '';
            }
        }
    }

    public get validInput(): boolean {
        return Boolean(
            this.title &&
            this.videoUrlValid &&
            this.videoOffsetMillis !== null &&
            this.musicUrlValid &&
            this.musicOffsetMillis !== null,
        );
    }

    public get videoUrlValid(): boolean {
        return youtubeUrlToId(this.videoUrl) !== '';
    }

    public get musicUrlValid(): boolean {
        return youtubeUrlToId(this.musicUrl) !== '';
    }

    public togglePlaying(): void {
        this.playing = !this.playing;
    }

    public updateTitle(event: ChangeEvent<HTMLInputElement>): void {
        this.title = event.target.value;
    }

    public updateVideoUrl(event: ChangeEvent<HTMLInputElement>): void {
        this.videoUrlValidationActive = true;
        this.videoUrl = event.target.value;
    }

    public updateVideoOffsetMillisWithSeconds(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.value) {
            const offset = Number.parseInt(event.target.value);
            if (!Number.isNaN(offset)) {
                this.videoOffsetMillis = offset * 1000;
            }
        } else {
            this.videoOffsetMillis = 0;
        }
    }

    public updateMusicUrl(event: ChangeEvent<HTMLInputElement>): void {
        this.musicUrlValidationActive = true;
        this.musicUrl = event.target.value;
    }

    public updateMusicOffsetMillisWithSeconds(event: ChangeEvent<HTMLInputElement>): void {
        if (event.target.value) {
            const offset = Number.parseInt(event.target.value);
            if (!Number.isNaN(offset)) {
                this.musicOffsetMillis = offset * 1000;
            }
        } else {
            this.musicOffsetMillis = 0;
        }
    }

    public saveLiedeo(): Promise<Liedeo> {
        const liedeoSave: LiedeoSave = {
            title: this.title,
            videoHost: 'YOUTUBE',
            videoId: youtubeUrlToId(this.videoUrl),
            videoOffsetMillis: this.videoOffsetMillis,
            videoPlaybackSpeed: '1',
            musicHost: 'YOUTUBE',
            musicId: youtubeUrlToId(this.musicUrl),
            musicOffsetMillis: this.musicOffsetMillis,
        };

        return this.liedeoStore.saveLiedeo(liedeoSave)
            .then((liedeo): Liedeo => {
                this.deleteLocalStorage();
                this.reset();
                return liedeo;
            });
    }

    private reset(): void {
        this.title = '';
        this.videoUrl = '';
        this.videoOffsetMillis = 0;
        this.musicUrl = '';
        this.musicOffsetMillis = 0;
    }

    private persistToLocalStorage(): void {
        const storeToSave = toJS(this);
        delete storeToSave.userStore;

        window.localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(storeToSave));
    }

    private setupFromLocalStorage(): void {
        const fromStore: CreatorStore | null = JSON.parse(window.localStorage.getItem(this.LOCAL_STORAGE_KEY) || 'null');

        if (fromStore) {
            this.title = fromStore.title;
            this.videoUrl = fromStore.videoUrl;
            this.videoOffsetMillis = fromStore.videoOffsetMillis;
            this.musicUrl = fromStore.musicUrl;
            this.musicOffsetMillis = fromStore.musicOffsetMillis;
        }
    }

    private deleteLocalStorage(): void {
        window.localStorage.removeItem(this.LOCAL_STORAGE_KEY);
    }
}

export default CreatorStore;
