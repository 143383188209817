import React, { useEffect } from 'react';
import { Container } from '@material-ui/core';
import useLiedeoCardsPerRow from '../../util/hooks/useLiedeoCardsPerRow';
import { useRootStore } from '../../data/stores/store-context';
import Grid from '@material-ui/core/Grid';
import LiedeoCardsRow from '../../components/liedeo-cards-row';
import { observer } from 'mobx-react';
import Typography from '@material-ui/core/Typography';
import chunk from 'lodash/chunk';
import { Liedeo, PageableResponse } from '../../types';
import { DEFAULT_LIEDEOS_PAGE_SIZE } from '../../util/request-helpers';
import Button from '@material-ui/core/Button';
import useFetchStateValue from '../../util/hooks/useFetchStateValue';
import { useFeedStyles } from './feed';

const FeedTop = observer((): JSX.Element => {
    const classes = useFeedStyles();

    return (
        <Container
            maxWidth='lg'
            className={ classes.content }
        >
            <Grid
                className={ classes.header }
                item
                xs={ 12 }
            >
                <Typography
                    variant={ 'h5' }
                    component={ 'h3' }
                    gutterBottom
                >
                    Top
                </Typography>
            </Grid>
            <LiedeoRows/>
            <NewLiedeos/>
        </Container>
    );
});


const LiedeoRows = observer((): JSX.Element => {
    const classes = useFeedStyles();
    const rootStore = useRootStore();

    const liedeoCardsPerRow = useLiedeoCardsPerRow();
    const liedeoChunks = chunk(rootStore.liedeoStore.topLiedeos, liedeoCardsPerRow);

    return (
        <>
            {
                // when new liedeos arrive, use them to create a new list and don't just render one list
                liedeoChunks.map((liedeos: Liedeo[]) => {
                    return (
                        <Grid
                            key={ liedeos[0].id }
                            className={ classes.row }
                            item
                            xs={ 12 }
                        >
                            <LiedeoCardsRow liedeos={ liedeos }/>
                        </Grid>
                    );
                })
            }
        </>
    );
});

const NewLiedeos = observer((): JSX.Element | null => {
    const classes = useFeedStyles();
    const rootStore = useRootStore();

    useEffect((): void => {
        rootStore.liedeoStore.fetchTopLiedeos();
    }, [rootStore.liedeoStore]);

    const fetchStateValue = useFetchStateValue<PageableResponse<Liedeo>>(rootStore.liedeoStore.fetchTopLiedeosState);
    const last = fetchStateValue && fetchStateValue.last;
    const loading = rootStore.liedeoStore.fetchTopLiedeosState && rootStore.liedeoStore.fetchTopLiedeosState.state === 'pending';

    const loadMoreLiedeos = (): void => {
        if (rootStore.liedeoStore.fetchTopLiedeosState?.state === 'fulfilled') {
            const page = rootStore.liedeoStore.fetchTopLiedeosState.value.number + 1;
            rootStore.liedeoStore.fetchTopLiedeos({ page, size: DEFAULT_LIEDEOS_PAGE_SIZE });
        }
    };

    const loadMoreText = loading ?
        'Loading...' :
        'Load more';

    return (!rootStore.liedeoStore.fetchTopLiedeosState || last) ?
        null :
        <Grid
            container
            justify='center'
            alignItems='center'
            className={ classes.row }
        >
            <Button
                variant={ 'contained' }
                color={ 'primary' }
                onClick={ loadMoreLiedeos }
                disabled={ loading }
            >
                { loadMoreText }
            </Button>
        </Grid>;
});

export default FeedTop;
