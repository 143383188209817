import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './pages/app';
import { configure } from 'mobx';
import axios from 'axios';

import tracking from './util/tracking';

// axios
axios.defaults.headers.accept = 'application/json';
process.env.NODE_ENV === 'production' ?
    axios.defaults.baseURL = 'https://mixey-backend.herokuapp.com' :
    //axios.defaults.baseURL = 'https://api.mixey.rocks' :
    //axios.defaults.baseURL = 'http://localhost:8080';
    axios.defaults.baseURL = 'https://mixey-backend.herokuapp.com';

// mobx
configure({
    enforceActions: 'observed',
});

// google analytics
tracking.initialize();

ReactDOM.render(
    <App/>
    ,
    document.getElementById('root'),
);
