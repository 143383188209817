import { action, computed, observable } from 'mobx';
import { ChangeEvent } from 'react';

class ProfileSettingsStore {

    @observable
    public newUsername = '';

    @observable
    public shouldValidateUsername = false;

    @action
    public updateNewUsername(event: ChangeEvent<HTMLInputElement>): void {
        this.newUsername = event.target.value;
        this.shouldValidateUsername = true;
    }

    @computed
    public get isUsernameValid(): boolean {
        return Boolean(
            !this.newUsername.includes(' ') &&
            this.newUsername.length >= 5 &&
            this.newUsername.length <= 15 &&
            this.newUsername.match("^[A-z0-9-_.]+$"),
        );
    }
}

const profileSettingsStore = new ProfileSettingsStore();
export default profileSettingsStore;



