import { makeAutoObservable, reaction, runInAction } from 'mobx';
import auth0Api from '../api/auth0-api';
import * as userApi from '../api/user-api';
import { Auth0DecodedHash, Auth0Error } from 'auth0-js';
import axios from 'axios';
import { User } from '../../types';
import tracking from '../../util/tracking';
import { startsWith } from 'lodash';

export class UserStore {

    public user?: User = undefined;
    public emailNotVerified = false;
    public userSession: Auth0DecodedHash = {};

    private userSessionExpireDate?: Date = undefined;

    public constructor() {
        makeAutoObservable(this);

        reaction(
            (): Auth0DecodedHash => this.userSession,
            (): void => {
                if (this.userSession.accessToken) {
                    // todo: use different axios instance (or request interceptors) to only send the header to api.mixey.rocks resources
                    axios.defaults.headers.common = { 'Authorization': `Bearer ${this.userSession.accessToken}` };

                    this.fetchUserProfile();
                }
            },
        );
    }

    public get isLoggedIn(): boolean {
        return Boolean(this.userSession && this.userSession.accessToken);
    }

    public get hasUsername(): boolean {
        return Boolean(this.user && this.user.username);
    }

    public login(): void {
        auth0Api.login();
    }

    public parseAuthenticationUrl(): Promise<void> {
        return auth0Api.parseAuthenticationUrl()
            .then((session) => this.setSession(session))
            .catch((error) => this.handleAuthError(error));
    }

    public logout(): void {
        auth0Api.logout();
    }

    public setSession(session: Auth0DecodedHash): void {
        this.userSession = session;

        if (session.accessToken) {
            this.userSessionExpireDate = new Date((session.expiresIn! * 1000) + new Date().getTime());
            this.emailNotVerified = false;
        }
    }

    public renewSession(): void {
        if (window.location.pathname === '/login-callback') {
            return;
        }

        auth0Api.renewSession()
            .then((session) => this.setSession(session))
            .catch((error) => this.handleAuthError(error));
    }

    public isAuthenticated(): boolean {
        return Boolean(
            this.userSessionExpireDate &&
            this.userSessionExpireDate.getTime() > new Date().getTime(),
        );
    }

    public triggerPasswordChangeEmail(): Promise<void> {
        if (this.user && !this.user.isSocial && this.user.email) {
            // todo: check if user used social login. if so, don't allow email change
            // todo: disable the password change button after successful trigger
            return auth0Api.triggerPasswordChangeEmail(this.user.email);
        }

        return Promise.reject('user not set');
    }

    public toggleTracking(): void {
        if (this.user) {
            // todo: sync with backend
            this.user.settings.allowGoogleTracking = !this.user.settings.allowGoogleTracking;
            tracking.isEnabled = this.user.settings.allowGoogleTracking;
        }
    }

    public setUsername(username: string): Promise<void> {
        return userApi.setUsername(username)
            .then((): void => {
                runInAction((): void => {
                    this.user!.username = username;
                });
            });
    }

    private fetchUserProfile(): void {
        Promise.all([
            auth0Api.fetchProfile(this.userSession!.accessToken!),
            userApi.fetchUser(),
        ]).then(([auth0User, mixeyUser]): void => {
            runInAction((): void => {
                // todo: think about where to put default user settings
                const auth0UserIsSocial = auth0User['https://mixey.rocks/identities'] ?
                    Boolean(auth0User['https://mixey.rocks/identities'].find(identity => identity.isSocial)) :
                    false;

                this.user = {
                    userId: auth0User.user_id,
                    email: auth0User.email,
                    profilePictureUrl: auth0User.picture,
                    isSocial: auth0UserIsSocial,
                    username: mixeyUser.username,
                    settings: mixeyUser.settings || { allowGoogleTracking: true },
                };
            });
        });
    }

    private handleAuthError(error: Auth0Error): void {
        const errorDescription = error.description || error.errorDescription;

        if (startsWith(errorDescription, '[email-not-verified]')) {
            this.emailNotVerified = true;
        } else {
            if (error.code !== 'login_required' && error.code !== 'unauthorized') {
                console.error(error);
            }
        }

        this.setSession({});
        this.user = undefined;
    }
}

const userStore = new UserStore();
export default userStore;

