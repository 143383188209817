import React from 'react';

export default function InlineLinkExternal(props: React.PropsWithChildren<{ url: string }>): JSX.Element {
    return (
        <a
            href={props.url}
            target='_blank'
            rel="noopener noreferrer"
        >
            { props.children }
        </a>
    );
}
