import { makeAutoObservable } from 'mobx';

export class NotificationStore {

    public open = false;
    public message = '';
    public variant: 'success' | 'warning' | 'error' | 'info' = 'info';
    public additionalAction?: React.ReactNode = undefined;
    public autoHideDurationMillis = 50000;

    constructor() {
        makeAutoObservable(this)
    }

    public showNotification(params: {
        message: string;
        variant: 'success' | 'warning' | 'error' | 'info';
        additionalAction?: React.ReactNode;
    }): void {
        this.message = params.message;
        this.variant = params.variant;
        this.additionalAction = params.additionalAction;
        this.open = true;
    }

    public close = (): void => {
        this.open = false;
    }
}

const notificationStore = new NotificationStore();
export default notificationStore;
