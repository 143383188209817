import * as React from 'react';
import UnstyledLink from './unstyled-link';

function InlineLinkInternal(props: React.PropsWithChildren<{ url: string }>): JSX.Element {
    return (
        <UnstyledLink
            to={ props.url }
        >
            { props.children }
        </UnstyledLink>
    );
}

export default InlineLinkInternal;
