import React from 'react';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ButtonLink from '../../components/links/button-link';

function NotFound(): JSX.Element {
    return (
        <Container maxWidth='lg'>
            <Typography>
                This page does not exist.
            </Typography>
            <ButtonLink
                to='/'
                variant='outlined'
                color='primary'
            >
                Back to Home
            </ButtonLink>
        </Container>
    );
}

export default NotFound;
