import React from 'react';
import { Box, createStyles, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import { PlayerStore } from '../player-store';
import { observer, useLocalStore } from 'mobx-react';
import { StyleRules } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ShareDialog from '../../../components/dialogs/share-dialog';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Liedeo } from '../../../types';
import { useRootStore } from '../../../data/stores/store-context';
import { action } from 'mobx';
import ReportDialog from '../../../components/dialogs/report-dialog';
import AccountRequiredDialog from '../../../components/dialogs/account-required-dialog';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        iconButtonMarginRight: {
            marginRight: theme.spacing(2),
        },
        isFavorite: {
            color: theme.palette.primary.main,
        },
    }),
);

interface MixeyActionsProps {
    playerStore: PlayerStore;
    liedeo: Liedeo;
}

interface MixeyActionsStore {
    isShareDialogOpen: boolean;
    isReportDialogOpen: boolean;
    isAccountRequiredDialogOpen: boolean;
    toggleIsShareDialogOpen: () => void;
    toggleIsReportDialogOpen: () => void;
    toggleIsAccountRequiredDialogOpen: () => void;
    optionsMenuAnchorElement: HTMLElement | undefined;
    setOptionsMenuAnchorElement: (optionsMenuAnchorElement: HTMLElement | undefined) => void;
}

export const MixeyActions = observer((props: MixeyActionsProps) => {
    const styles = useStyles();
    const rootStore = useRootStore();
    const localStore = useLocalStore<MixeyActionsStore>(() => ({
        isShareDialogOpen: false,
        isReportDialogOpen: false,
        isAccountRequiredDialogOpen: false,
        toggleIsShareDialogOpen: action(() => {
            localStore.isShareDialogOpen = !localStore.isShareDialogOpen;
        }),
        toggleIsReportDialogOpen: action(() => {
            localStore.isReportDialogOpen = !localStore.isReportDialogOpen;
        }),
        toggleIsAccountRequiredDialogOpen: action(() => {
            localStore.isAccountRequiredDialogOpen = !localStore.isAccountRequiredDialogOpen;
        }),
        optionsMenuAnchorElement: undefined,
        setOptionsMenuAnchorElement: action((optionsMenuAnchorElement: HTMLElement | undefined) => {
            localStore.optionsMenuAnchorElement = optionsMenuAnchorElement;
        }),
    }));
    const open = Boolean(localStore.optionsMenuAnchorElement);

    const isFavoriteStyle: string | undefined = rootStore.favoriteStore.isFavoriteLiedeo(props.liedeo) ?
        styles.isFavorite :
        undefined;

    const favoriteButtonTitle: string = rootStore.favoriteStore.isFavoriteLiedeo(props.liedeo) ?
        'Remove from favorites' :
        'Add to favorites';

    const saveOrDeleteFavorite = (): void => {
        if (rootStore.userStore.isLoggedIn) {
            rootStore.favoriteStore.isFavoriteLiedeo(props.liedeo) ?
                rootStore.favoriteStore.deleteFavoriteLiedeo(props.liedeo) :
                rootStore.favoriteStore.saveFavoriteLiedeo(props.liedeo);
        } else {
            localStore.toggleIsAccountRequiredDialogOpen();
        }
    };

    return (
        <Box display='inline-flex' alignItems='center'>
            <IconButton
                size='small'
                onClick={ saveOrDeleteFavorite }
                aria-label="favorite"
                title={ favoriteButtonTitle }
            >
                <FavoriteIcon className={ isFavoriteStyle }/>
            </IconButton>
            <Typography
                color='textSecondary'
                display='inline'
                className={ styles.iconButtonMarginRight }>
                { props.liedeo.amountOfFavorites }
            </Typography>
            <AccountRequiredDialog
                open={localStore.isAccountRequiredDialogOpen}
                onClose={localStore.toggleIsAccountRequiredDialogOpen}
            />
            <IconButton
                aria-label="share"
                size='small'
                className={ styles.iconButtonMarginRight }
                onClick={ localStore.toggleIsShareDialogOpen }
            >
                <ShareIcon/>
            </IconButton>
            <ShareDialog
                open={ localStore.isShareDialogOpen }
                liedeo={ props.liedeo }
                onClose={ localStore.toggleIsShareDialogOpen }
            />
            <IconButton
                aria-label="more"
                size='small'
                onClick={ (event): void => {
                    localStore.setOptionsMenuAnchorElement(event.currentTarget);
                } }
            >
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                id="options-menu"
                anchorEl={ localStore.optionsMenuAnchorElement }
                open={ open }
                getContentAnchorEl={ null } // to put menu item bellow anchor
                anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                onClose={ (): void => {
                    // gets called on outside click
                    localStore.setOptionsMenuAnchorElement(undefined);
                } }
            >
                <MenuItem onClick={(): void => {
                    localStore.setOptionsMenuAnchorElement(undefined);
                    localStore.toggleIsReportDialogOpen();
                } }>
                    Report
                </MenuItem>
            </Menu>
            <ReportDialog open={ localStore.isReportDialogOpen } onClose={ localStore.toggleIsReportDialogOpen }/>
        </Box>
    );
});
