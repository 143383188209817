import React from 'react';
import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useRootStore } from '../../data/stores/store-context';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        root: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        loginButton: {
            marginTop: theme.spacing(3),
        },
    }),
);

interface ReportDialogProps {
    open: boolean;

    onClose(): void;
}

export default function AccountRequiredDialog(props: ReportDialogProps): JSX.Element {
    const styles = useStyles();
    const rootStore = useRootStore();

    const handleClose = (): void => {
        props.onClose();
    };

    return (
        <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title" open={ props.open }>
            <DialogTitle id="simple-dialog-title">
                Account required
                <IconButton aria-label="close" className={ styles.closeButton } onClick={ handleClose }>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <Box className={ styles.root }>
                <Typography>
                    To use this feature, please register an account or login.
                </Typography>
                <Button
                    variant='outlined'
                    fullWidth={ true }
                    className={ styles.loginButton }
                    onClick={ (): void => rootStore.userStore.login() }
                >
                    Login / Register
                </Button>
            </Box>
        </Dialog>
    );
}
