import * as React from 'react';
import { ChangeEvent, PureComponent } from 'react';
import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import { observer } from 'mobx-react';
import { StyleRules } from '@material-ui/core/styles';
import { RootContext } from '../../data/stores/store-context';
import profileSettingsStore from './profile-settings-store';
import { CONTACT_EMAIL_ADDRESS } from '../../util/content';

const styles = (theme: Theme): StyleRules => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
    },
    contentParagraph: {
        marginBottom: theme.spacing(3),
    },
    textField: {
        marginBottom: theme.spacing(2),
    },
});

@observer
class ProfileSettings extends PureComponent<WithStyles<typeof styles>> {

    public static contextType = RootContext;
    public context!: React.ContextType<typeof RootContext>;

    public render(): JSX.Element {
        const username = this.context.userStore.user ? this.context.userStore.user.username : null;
        const email = this.context.userStore.user ? this.context.userStore.user.email : null;
        const isSocial = this.context.userStore.user ? this.context.userStore.user.isSocial : false;

        return (
            <Container
                maxWidth='lg'
                className={ this.props.classes.root }
            >
                <Grid
                    container
                    spacing={ 1 }
                    className={ this.props.classes.contentParagraph }
                >
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'h6' } component={ 'h2' }>
                            Username / Account URL
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'body1' }>
                            Your username will be visible to the public. It is used as a link to your mixes and
                            favorites. You cannot change your username once it's set.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <form noValidate>
                            <TextField
                                error={ !profileSettingsStore.isUsernameValid && profileSettingsStore.shouldValidateUsername }
                                className={ this.props.classes.textField }
                                id='username'
                                label='Your Username'
                                disabled={ Boolean(username) }
                                type='username'
                                autoComplete='new-username'
                                fullWidth
                                margin={ 'normal' }
                                variant={ 'outlined' }
                                helperText={ username ? '' : '5-15 characters, a-z, A-Z, 0-9 and -_.' }
                                value={ username || profileSettingsStore.newUsername }
                                onChange={ (event: ChangeEvent<HTMLInputElement>): void => profileSettingsStore.updateNewUsername(event) }
                            />
                            { !username &&
                            <Button
                                variant={ 'outlined' }
                                onClick={ this.saveUsername }
                                disabled={ !profileSettingsStore.isUsernameValid }
                            >
                                Save username
                            </Button>
                            }
                        </form>
                        {
                            (username || profileSettingsStore.newUsername) &&
                            <Link
                                href={ `/users/${ username || profileSettingsStore.newUsername }` }
                                variant={ 'inherit' }
                                color={ 'inherit' }
                                underline={ 'none' }
                                component={ 'a' }
                            >
                                { `https://mixey.rocks/users/${ username || profileSettingsStore.newUsername }` }
                            </Link>
                        }
                    </Grid>
                </Grid>
                { !isSocial && (
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'h6' } component={ 'h2' }>
                                E-Mail
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'body1' }>
                                Your E-Mail is only used for login and password reset. You cannot change your E-Mail
                                address.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <TextField
                                className={ this.props.classes.textField }
                                id='email'
                                label='Your Email'
                                disabled={ true }
                                margin={ 'normal' }
                                variant={ 'outlined' }
                                value={ email || '' }
                            />
                        </Grid>
                    </Grid>
                ) }
                <Grid
                    container
                    spacing={ 1 }
                    className={ this.props.classes.contentParagraph }
                >
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'h6' } component={ 'h2' }>
                            Password
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        {
                            isSocial ?
                                (
                                    <Typography variant={ 'body1' }>
                                        You are logged in via 3rd Party. Please go to your provider to change the
                                        password (e.g. Facebook).
                                    </Typography>
                                ) : (
                                    <Typography variant={ 'body1' }>
                                        To change your password, click the following button. You will then receive an
                                        E-Mail with a link to change your password.
                                    </Typography>
                                )
                        }
                    </Grid>
                    {
                        !isSocial && (
                            <Grid
                                item
                                xs={ 12 }
                            >
                                <Button
                                    variant={ 'outlined' }
                                    onClick={ this.triggerPasswordResetEMail }
                                >
                                    Trigger password change E-Mail
                                </Button>
                            </Grid>
                        )
                    }
                </Grid>
                <Grid
                    container
                    spacing={ 1 }
                    className={ this.props.classes.contentParagraph }
                >
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'h6' } component={ 'h2' }>
                            Account deletion
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'body1' }>
                            Please let us know if you want to delete your account: { ' ' }
                            <a href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=Account%20deletion`}>{CONTACT_EMAIL_ADDRESS}</a>.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >

                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={ 1 }
                    className={ this.props.classes.contentParagraph }
                >
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'h6' } component={ 'h2' }>
                            Tracking
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <Typography variant={ 'body1' }>
                            We are using Google Analytics tracking to improve our page. You can opt-out if you want to.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={ 12 }
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={ 'primary' }
                                    checked={ this.context.userStore.user ?
                                        this.context.userStore.user.settings.allowGoogleTracking :
                                        true
                                    }
                                    onChange={ (): void => {
                                        this.context.userStore.toggleTracking();
                                    } }
                                />
                            }
                            label="Tracking"
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }

    // todo: separate in different components
    // todo: add google tracking opt-out

    private saveUsername = (event: React.MouseEvent): void => {
        event.preventDefault();

        this.context.userStore.setUsername(profileSettingsStore.newUsername)
            .then((): void => {
                this.context.notificationStore.showNotification({
                    variant: 'success',
                    message: 'Username saved',
                });
            })
            .catch((): void => {
                this.context.notificationStore.showNotification({
                    variant: 'error',
                    message: 'Could not save username. Please try again later.',
                });
            });
    };

    private triggerPasswordResetEMail = (): void => {
        this.context.userStore.triggerPasswordChangeEmail()
            .then((): void => {
                this.context.notificationStore.showNotification({
                    variant: 'success',
                    message: 'Password change E-Mail send. Please check your E-Mail inbox.',
                });
            })
            .catch((): void => {
                this.context.notificationStore.showNotification({
                    variant: 'error',
                    message: 'Could not send password reset E-Mail. Please try again later.',
                });
            });
    };
}

export default withStyles(styles)(ProfileSettings);
