import React, { ChangeEvent, PureComponent, RefObject } from 'react';
import ReactPlayer from 'react-player';
import { Button, Container, Grid, TextField, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { StyleRules } from '@material-ui/core/styles';
import CreatorStore from './creator-store';
import { RootContext } from '../../data/stores/store-context';
import SuccessActionNotificationButton from './success-action-notification-button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { UserStore } from '../../data/stores/user-store';
import { LiedeoStore } from '../../data/stores/liedeo-store';
import { NotificationStore } from '../../components/notification/store';
import CreatorHtmlHeader from './creator-html-header';
import { handleOnFocusSelectAll } from '../../util/event-handler';

const styles = (theme: Theme): StyleRules => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(5),
    },
    inputMargin: {
        marginRight: theme.spacing(1),
    },
    contentParagraph: {
        marginBottom: theme.spacing(3),
    },
});

const Title = observer((props: { creatorStore: CreatorStore }): JSX.Element => {
    const classes = makeStyles(styles)();

    return <TextField
        label='Title'
        placeholder='Enter a title the describes your Mixey'
        InputLabelProps={ {
            shrink: true,
        } }
        fullWidth
        className={ classes.inputMargin }
        margin={ 'normal' }
        variant={ 'outlined' }
        value={ props.creatorStore.title }
        onChange={ (event: ChangeEvent<HTMLInputElement>): void => props.creatorStore.updateTitle(event) }
    />;
});

const VideoUrl = observer((props: { creatorStore: CreatorStore }): JSX.Element => {
    const classes = makeStyles(styles)();

    return <TextField
        label='Youtube Video URL'
        placeholder='Paste the URL for the video'
        error={ props.creatorStore.videoUrlValidationActive && !props.creatorStore.videoUrlValid }
        InputLabelProps={ {
            shrink: true,
        } }
        fullWidth
        className={ classes.inputMargin }
        margin={ 'normal' }
        variant={ 'outlined' }
        value={ props.creatorStore.videoUrl }
        onFocus={ handleOnFocusSelectAll }
        onChange={ (event: ChangeEvent<HTMLInputElement>): void => props.creatorStore.updateVideoUrl(event) }
    />;
});

const VideoOffset = observer((props: { creatorStore: CreatorStore; onChange: () => void }): JSX.Element => {
    return <TextField
        label={ 'Offset (in Seconds)' }
        fullWidth
        margin={ 'normal' }
        variant={ 'outlined' }
        disabled={ !Boolean(props.creatorStore.videoUrl) }
        value={ props.creatorStore.videoOffsetMillis !== null ? props.creatorStore.videoOffsetMillis / 1000 : '' }
        onChange={ (event: ChangeEvent<HTMLInputElement>): void => {
            props.creatorStore.updateVideoOffsetMillisWithSeconds(event);
            props.onChange();
        } }
    />;
});

const MusicUrl = observer((props: { creatorStore: CreatorStore }): JSX.Element => {
    const classes = makeStyles(styles)();

    return <TextField
        label='Youtube Music URL'
        placeholder='Paste the URL for the song'
        error={ props.creatorStore.musicUrlValidationActive && !props.creatorStore.musicUrlValid }
        InputLabelProps={ {
            shrink: true,
        } }
        fullWidth
        className={ classes.inputMargin }
        margin={ 'normal' }
        variant={ 'outlined' }
        value={ props.creatorStore.musicUrl }
        onFocus={ handleOnFocusSelectAll }
        onChange={ (event: ChangeEvent<HTMLInputElement>): void => props.creatorStore.updateMusicUrl(event) }
    />;
});

const MusicOffset = observer((props: { creatorStore: CreatorStore; onChange: () => void }): JSX.Element => {
    return <TextField
        label={ 'Offset (in Seconds)' }
        fullWidth
        margin={ 'normal' }
        variant={ 'outlined' }
        disabled={ !Boolean(props.creatorStore.musicUrl) }
        value={ props.creatorStore.musicOffsetMillis !== null ? props.creatorStore.musicOffsetMillis / 1000 : '' }
        onChange={ (event: ChangeEvent<HTMLInputElement>): void => {
            props.creatorStore.updateMusicOffsetMillisWithSeconds(event);
            props.onChange();
        } }
    />;
});

const VideoPlayer = observer((props: { creatorStore: CreatorStore; playerRef: RefObject<ReactPlayer> }): JSX.Element => {
    return <ReactPlayer
        ref={ props.playerRef }
        url={ `${ props.creatorStore.videoUrl }&t=${ (props.creatorStore.videoOffsetMillis || 0) / 1000 }s` }
        volume={ 0 }
        playing={ props.creatorStore.playing }
        controls={ true }
        width={ '100%' }
    />;
});

const MusicPlayer = observer((props: { creatorStore: CreatorStore; playerRef: RefObject<ReactPlayer> }): JSX.Element => {
    return <ReactPlayer
        ref={ props.playerRef }
        url={ `${ props.creatorStore.musicUrl }&t=${ (props.creatorStore.musicOffsetMillis || 0) / 1000 }s` }
        volume={ 100 }
        playing={ props.creatorStore.playing }
        controls={ true }
        width={ '100%' }
    />;
});

const VideoPlayerGrid = observer((props: { creatorStore: CreatorStore; children: React.ReactNode }): JSX.Element => {
    return props.creatorStore.videoUrlValid ?
        (
            <Grid item xs={ 12 } sm={ 6 }>
                <Grid item xs={ 12 }>
                    <Typography>
                        Video (muted):
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    { props.children }
                </Grid>
            </Grid>
        ) :
        <></>;
});

const MusicPlayerGrid = observer((props: { creatorStore: CreatorStore; children: React.ReactNode }): JSX.Element => {
    return props.creatorStore.musicUrlValid ?
        (
            <Grid item xs={ 12 } sm={ 6 }>
                <Grid item xs={ 12 }>
                    <Typography>
                        Music:
                    </Typography>
                </Grid>
                <Grid item xs={ 12 }>
                    { props.children }
                </Grid>
            </Grid>
        ) :
        <></>;
});

const PlayerControlsGrid = observer((props: {
    creatorStore: CreatorStore;
    videoPlayerRef: RefObject<ReactPlayer>; // todo: use mobx.when in parent component for seeking
    musicPlayerRef: RefObject<ReactPlayer>;
}): JSX.Element => {
    const classes = makeStyles(styles)();

    const onPlay = (): void => {
        props.creatorStore.togglePlaying();
    };

    const onReset = (): void => {
        props.videoPlayerRef.current!.seekTo(props.creatorStore.videoOffsetMillis / 1000, 'seconds');
        props.musicPlayerRef.current!.seekTo(props.creatorStore.musicOffsetMillis / 1000, 'seconds');
    };

    return (props.creatorStore.videoUrlValid || props.creatorStore.musicUrlValid) ? (
        <Grid
            container
            spacing={ 1 }
            className={ classes.contentParagraph }
        >
            <Grid
                item
                xs={ 12 }
            >
                <Typography variant={ 'h6' } component={ 'h2' }>
                    Test
                </Typography>
            </Grid>
            <Grid
                item
                xs={ 12 }
            >
                <Button
                    variant={ 'outlined' }
                    className={ classes.inputMargin }
                    onClick={ onPlay }
                >
                    {
                        props.creatorStore.playing ?
                            'Pause' :
                            'Play'

                    }
                </Button>
                <Button
                    variant={ 'outlined' }
                    className={ classes.inputMargin }
                    onClick={ onReset }
                >
                    Reset
                </Button>
            </Grid>
        </Grid>
    ) : <></>;
});

const SaveButton = observer((props: {
    creatorStore: CreatorStore;
    userStore: UserStore;
    liedeoStore: LiedeoStore;
    notificationStore: NotificationStore;
}): JSX.Element => {
    const onSave = (): void => {

        props.creatorStore.saveLiedeo()
            .then(((liedeo): void => {
                props.notificationStore.showNotification({
                    message: 'Your Mixey was saved!',
                    variant: 'success',
                    additionalAction: (
                        <SuccessActionNotificationButton
                            liedeoId={ liedeo.id }
                            onClick={ props.notificationStore.close }
                        />
                    ),
                });
            }))
            .catch((error): void => {
                console.error('could not save liedeo', error);

                props.notificationStore.showNotification({
                    message: 'Could not save your Mixey.',
                    variant: 'error',
                });
            });
    };

    return <Button
        fullWidth
        variant={ 'contained' }
        color={ 'primary' }
        disabled={ !props.creatorStore.validInput || !props.userStore.hasUsername }
        onClick={ onSave }
    >
        Save
    </Button>;
});

const ErrorLabel = observer((props: { creatorStore: CreatorStore }): JSX.Element => {
    const classes = makeStyles(styles)();

    return props.creatorStore.errorMessage ?
        (
            <Grid
                container
                spacing={ 1 }
                className={ classes.contentParagraph }
                justify={ 'flex-end' }
            >
                <Typography variant='subtitle2' color='error'>
                    { props.creatorStore.errorMessage }
                </Typography>
            </Grid>
        ) : <></>;
});


@observer
class Creator extends PureComponent<WithStyles<typeof styles>> {

    private youtubePlayerVideoRef = React.createRef<ReactPlayer>();
    private youtubePlayerMusicRef = React.createRef<ReactPlayer>();

    public static contextType = RootContext;
    public context!: React.ContextType<typeof RootContext>;

    private creatorStore: CreatorStore;

    public constructor(props: any, context: any) {
        super(props, context);

        // todo: maybe useLocalStore
        this.creatorStore = new CreatorStore(context.userStore, context.liedeoStore);
    }

    public render(): JSX.Element {
        return (
            <>
                <CreatorHtmlHeader/>
                <Container
                    maxWidth='lg'
                    className={ this.props.classes.root }
                >

                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'h3' } component={ 'h1' }>
                                Mixey Creator
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'body1' } component={ 'p' }>
                                Please fill in all inputs to create your own mix.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'h6' } component={ 'h2' }>
                                Your Mixey
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Title creatorStore={ this.creatorStore }/>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography variant={ 'h6' } component={ 'h2' }>
                                Video
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            sm={ 8 }
                            lg={ 10 }

                        >
                            <VideoUrl creatorStore={ this.creatorStore }/>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            sm={ 4 }
                            lg={ 2 }
                        >
                            <VideoOffset creatorStore={ this.creatorStore } onChange={ () => {
                                if (this.youtubePlayerVideoRef.current) {
                                    this.youtubePlayerVideoRef.current.seekTo(this.creatorStore.videoOffsetMillis / 1000, 'seconds');
                                }

                                if (this.youtubePlayerMusicRef.current) {
                                    this.youtubePlayerMusicRef.current.seekTo(this.creatorStore.musicOffsetMillis / 1000, 'seconds');
                                }
                            } }/>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <Typography
                                variant={ 'h6' }
                                component={ 'h2' }
                            >
                                Music
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            sm={ 8 }
                            lg={ 10 }
                        >
                            <MusicUrl creatorStore={ this.creatorStore }/>
                        </Grid>
                        <Grid
                            item
                            xs={ 12 }
                            sm={ 4 }
                            lg={ 2 }
                        >
                            <MusicOffset creatorStore={ this.creatorStore } onChange={ (): void => {
                                this.youtubePlayerVideoRef.current!.seekTo(this.creatorStore.videoOffsetMillis / 1000, 'seconds');
                                this.youtubePlayerMusicRef.current!.seekTo(this.creatorStore.musicOffsetMillis / 1000, 'seconds');
                            } }/>
                        </Grid>
                    </Grid>
                    <PlayerControlsGrid
                        creatorStore={ this.creatorStore }
                        videoPlayerRef={ this.youtubePlayerVideoRef }
                        musicPlayerRef={ this.youtubePlayerMusicRef }
                    />
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <VideoPlayerGrid creatorStore={ this.creatorStore }>
                            <VideoPlayer
                                creatorStore={ this.creatorStore }
                                playerRef={ this.youtubePlayerVideoRef }
                            />
                        </VideoPlayerGrid>
                        <MusicPlayerGrid creatorStore={ this.creatorStore }>
                            <MusicPlayer
                                creatorStore={ this.creatorStore }
                                playerRef={ this.youtubePlayerMusicRef }
                            />
                        </MusicPlayerGrid>
                    </Grid>
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                    >
                        <Grid item xs={ 12 }>
                            <Typography variant={ 'h6' } component={ 'h2' }>
                                Publish
                            </Typography>
                        </Grid>
                        <Grid item xs={ 12 }>
                            <Typography variant={ 'body1' } component={ 'p' }>
                                Before you publish your mix make sure Video and Music fit well together. Please also
                                provide
                                the correct Artist and Title for your Music Video.
                            </Typography>
                            <Typography variant={ 'body1' } component={ 'p' }>
                                After you have clicked "Save", your Mix will become publicly available.
                            </Typography>
                            <Typography variant={ 'body1' } component={ 'p' }>
                                Thank you for creating a new Mix!
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={ 1 }
                        className={ this.props.classes.contentParagraph }
                        justify={ 'flex-end' }
                    >
                        <Grid
                            item
                            xs={ 12 }
                            md={ 4 }
                        >
                            <SaveButton
                                creatorStore={ this.creatorStore }
                                userStore={ this.context.userStore }
                                liedeoStore={ this.context.liedeoStore }
                                notificationStore={ this.context.notificationStore }
                            />
                        </Grid>
                    </Grid>
                    <ErrorLabel creatorStore={ this.creatorStore }/>
                </Container>
            </>
        );
    }
}

export default withStyles(styles)(Creator);
