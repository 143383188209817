import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import UnstyledLink from './unstyled-link';

interface TypographyLinkProps extends TypographyProps {
    to: string;
}

function TypographyLink(props: TypographyLinkProps): JSX.Element {
    const { to, ...rest } = props;

    return (
        <Typography
            { ...rest }
        >
            <UnstyledLink to={ to }>
                { props.children }
            </UnstyledLink>
        </Typography>
    );
}

export default TypographyLink;
