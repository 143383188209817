import React, { PureComponent } from 'react';
import { WithWidth } from '@material-ui/core/withWidth';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootContext } from '../../data/stores/store-context';
import { Container } from '@material-ui/core';

interface LoginCallbackProps extends RouteComponentProps, WithWidth {}

@observer
class LoginCallback extends PureComponent<LoginCallbackProps> {

    public static contextType = RootContext;
    public context!: React.ContextType<typeof RootContext>;

    public componentDidMount(): void {
        this.context.userStore.parseAuthenticationUrl()
            .catch((error): void => {
                console.error(error);
            })
            .finally(() => this.props.history.push('/'))
    }

    public render(): JSX.Element {
        return (
            <Container>
                Loading user data...
            </Container>
        );
    }
}

export default withRouter(LoginCallback);
