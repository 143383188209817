import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import LiedeoCardsRow from '../../components/liedeo-cards-row';
import { observer } from 'mobx-react';
import { useRootStore } from '../../data/stores/store-context';
import useLiedeoCardsPerRow from '../../util/hooks/useLiedeoCardsPerRow';
import { useHomeStyles } from './home';
import { SeeAllMobileButton } from './components/see-all-mobile-button';
import { HomeLiedeosHeader } from './components/home-liedeos-header';

const HomeTop = observer((): JSX.Element | null => {
    const styles = useHomeStyles();

    return (
        <div className={ styles.liedeoRow }>
            <HomeLiedeosHeader type={'top'}/>
            <Liedeos/>
        </div>
    );
});

const Liedeos = observer((): JSX.Element => {
    const rootStore = useRootStore();

    useEffect((): void => {
        rootStore.liedeoStore.fetchTopLiedeos();
    }, [rootStore.liedeoStore]);

    const liedeoCardsPerRow = useLiedeoCardsPerRow();
    const liedeos = rootStore.liedeoStore.topLiedeos.slice(0, liedeoCardsPerRow);
    const isLoading = !rootStore.liedeoStore.fetchTopLiedeosState || rootStore.liedeoStore.fetchTopLiedeosState.state === 'pending';

    if (rootStore.liedeoStore.fetchTopLiedeosState && rootStore.liedeoStore.fetchTopLiedeosState.state === 'rejected') {
        return <div>Error. Please try again...</div>;
    }

    return (
        <>
            <Grid
                item
                xs={ 12 }
            >
                <LiedeoCardsRow loading={ isLoading } liedeos={ liedeos }/>
            </Grid>
            <SeeAllMobileButton type={'top'}/>
        </>
    );
});

export default HomeTop;
