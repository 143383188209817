import React from 'react';
import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { CONTACT_EMAIL_ADDRESS } from '../../util/content';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        root: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    }),
);

interface ReportDialogProps {
    open: boolean;
    onClose(): void;
}

export default function ReportDialog(props: ReportDialogProps): JSX.Element {
    const styles = useStyles();

    const handleClose = (): void => {
        props.onClose();
    };

    const url = window.location.href;
    const emailSubject = `Report%20${ url }`;

    return (
        <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title" open={ props.open }>
            <DialogTitle id="simple-dialog-title">
                Report
                <IconButton aria-label="close" className={ styles.closeButton } onClick={ handleClose }>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <Box className={ styles.root }>
                <Typography>
                    <span>If this Mixey violates the Terms of Service please contact us at: </span>
                    <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }?subject=${ emailSubject }` }>{ CONTACT_EMAIL_ADDRESS }</a>
                </Typography>
            </Box>
        </Dialog>
    );
}
