import React, { PureComponent } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Avatar, Button, Container, Menu, MenuItem, Theme, withStyles, WithStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootContext } from '../../data/stores/store-context';
import headerStore from './header-store';
import ButtonLink from '../links/button-link';
import TypographyLink from '../links/typography-link';

const styles = (theme: Theme): StyleRules => ({
    root: {
        background: 'linear-gradient(90deg, rgba(56,44,116,1) 0%, rgba(74,57,153,1) 24%, rgba(56,44,116,1) 100%)',
    },
    fullWidthTitle: {
        flexGrow: 1,
    },
    cursiveText: {
        fontFamily: '\'Galada\', cursive',
        fontSize: '1.75rem',
    },
    button: {
        color: '#FFF', // todo: use background theme color
        borderColor: '#FFF',
    },
    buttonMargin: {
        marginRight: theme.spacing(2),
    },
});

interface HeaderProps extends RouteComponentProps, WithStyles<typeof styles> {
}

@observer
class Header extends PureComponent<HeaderProps> {

    public static contextType = RootContext;
    public context!: React.ContextType<typeof RootContext>;

    public componentDidMount(): void {
        this.context.userStore.renewSession();
    }

    public render(): JSX.Element {
        const { classes } = this.props;

        return (
            <AppBar position="static" className={ classes.root }>
                <Container maxWidth='lg'>
                    <Toolbar disableGutters={ true }>
                        <div className={ classes.fullWidthTitle }>
                            <TypographyLink
                                to="/"
                                variant="h6"
                                color="inherit"
                                className={ classes.cursiveText }
                            >
                                mixey
                            </TypographyLink>
                        </div>
                        {
                            this.context.userStore.user ?
                                this.renderUserProfile() :
                                this.renderLoginActions()
                        }
                    </Toolbar>
                </Container>
            </AppBar>
        );
    }

    private renderUserProfile(): JSX.Element {
        const userProfilePicture = this.context.userStore.user!.profilePictureUrl ?
            this.context.userStore.user!.profilePictureUrl :
            '';
        const userNicknameFallback = this.context.userStore.user!.profilePictureUrl ?
            '' :
            this.context.userStore.user!.email ?
                this.context.userStore.user!.email.substring(0, 2).toUpperCase() :
                'MY';

        return (
            <>
                <ButtonLink
                    to='/creator'
                    variant='text'
                    className={ `${ this.props.classes.buttonMargin } ${ this.props.classes.button }` }
                >
                    + Create
                </ButtonLink>
                <div>
                    <div
                        aria-owns={ headerStore.open ? 'menu-appbar' : undefined }
                        aria-haspopup="true"
                        style={ { cursor: 'pointer' } }
                        onClick={ (event): void => {
                            headerStore.setMenuAnchorElement(event.currentTarget);
                        } }
                    >
                        <Avatar
                            style={ { height: '32px', width: '32px' } }
                            src={ userProfilePicture }
                        >
                            { userNicknameFallback }
                        </Avatar>
                    </div>
                    <Menu
                        id="menu-appbar"
                        anchorEl={ headerStore.menuAnchorElement }
                        getContentAnchorEl={ null } // to put menu item bellow anchor
                        anchorOrigin={ { vertical: 'bottom', horizontal: 'right' } }
                        transformOrigin={ { vertical: 'top', horizontal: 'right' } }
                        open={ headerStore.open }
                        onClose={ (): void => {
                            // gets called on outside click
                            headerStore.setMenuAnchorElement(undefined);
                        } }
                    >
                        <MenuItem component='a' href='/profile-settings' onClick={ this.goTo('/profile-settings') }>
                            Profile Settings
                        </MenuItem>
                        <MenuItem onClick={ this.logout }>
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            </>
        );
    }

    private renderLoginActions(): JSX.Element {
        return (
            <>
                <Button
                    variant='outlined'
                    onClick={ this.loginOrRegister }
                    className={ this.props.classes.button }
                >
                    Login / Register
                </Button>
            </>
        );
    }

    private loginOrRegister = (): void => {
        this.context.userStore.login();
    };

    private goTo = (url: string) => (event: React.MouseEvent): void => {
        event.preventDefault();
        this.props.history.push(url);

        this.closeMenu();
    };

    private logout = (): void => {
        this.closeMenu();

        this.context.userStore.logout();
    };

    private closeMenu = (): void => {
        headerStore.setMenuAnchorElement(undefined);
    };
}

export default withRouter(withStyles(styles)(Header));
