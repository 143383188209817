import axios, { AxiosResponse } from 'axios';
import { MixeyUser } from '../../types';

export function fetchUser(): Promise<MixeyUser> {
    return axios.get<MixeyUser, AxiosResponse<MixeyUser>>('/user')
        .then((response): MixeyUser => {
            return response.data;
        })
        .catch((error): MixeyUser => {
            console.error('could not fetch user', error);

            throw error;
        });
}

export function setUsername(username: string): Promise<void> {
    return axios.patch<void, AxiosResponse<void>>('/user/username', {
        username: username,
    }, {
        headers: {
            'Content-Type': 'application/json',
        },
    }).then((): void => {
        console.log('Username edited!')
    }).catch((error): void => {
        console.error('could not set username', error);

        throw error;
    });
}
