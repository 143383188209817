// https://stackoverflow.com/a/51870158
const youtubeUrlIdRegex = /^(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;

export const youtubeUrlToId = function (youtubeUrl: string): string {
    if (!youtubeUrl) {
        return '';
    }

    const destructedUrl = youtubeUrl.match(youtubeUrlIdRegex);

    if (!destructedUrl || destructedUrl.length < 7) {
        return '';
    }

    const id = destructedUrl[7];

    // we assume that id's are always 11 characters long, although this is not guaranteed. as soon as we know an id
    // that has a different length, we need to remove this check. alternative is to ask the youtube api if the id
    // exists
    if (id.length !== 11) {
        return '';
    }

    return id;
}
