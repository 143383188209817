import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from '@material-ui/core';
import { useRootStore } from '../../data/stores/store-context';
import { observer } from 'mobx-react';

const EmailNotVerifiedDialog: React.FC = observer((props) => {
    const rootStore = useRootStore();
    const emailNotVerified = rootStore.userStore.emailNotVerified;

    const [userClose, setUserClose] = React.useState(false);

    const open = emailNotVerified && !userClose;

    const handleClose = (): void => {
        setUserClose(true);
    };

    const handleVerified = (): void => {
        rootStore.userStore.login();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Verify your E-Mail address</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please check your E-Mail inbox to verify your E-Mail address. You can only login with a
                        verified E-Mail.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleVerified}>
                        I verified my E-Mail
                    </Button>
                    <Button onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
});

export default EmailNotVerifiedDialog;
