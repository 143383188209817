import queryString from "query-string";
import toNumber from 'lodash/toNumber';

export function extractLiedeoIdFromUrl(): number | undefined {
    const queryParams = queryString.parse(window.location.search);

    const idParam = queryParams['id'];

    if (typeof idParam === 'string') {
        const idString = Array.isArray(idParam) ? idParam[0] : idParam;
        const id = toNumber(idString);

        if (Number.isNaN(id)) {
            return undefined;
        }
        return id;
    }
    return undefined;
}
