import React from 'react';
import { Container, createStyles, Divider, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import Authenticated from '../../components/authenticated/authenticated';
import HomeFavorites from './home-favorites';
import HomeLatest from './home-latest';
import HomeTop from './home-top';

export type HomeLiedeoTypes = 'top' | 'latest' | 'favorites';

export const useHomeStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        header: {
            paddingBottom: theme.spacing(1),
        },
        liedeoRow: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(5),
        },
        seeLatestMobileButton: {
            paddingTop: theme.spacing(2),
        },
    }),
);

export default function Home(): JSX.Element {
    return (
        <Container
            maxWidth='lg'
        >
            <HomeTop/>
            <Divider component={ 'hr' }/>
            <HomeLatest/>
            <Authenticated>
                <Divider component={ 'hr' }/>
                <HomeFavorites/>
            </Authenticated>
        </Container>
    );
}
