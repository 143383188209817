import { makeAutoObservable } from 'mobx';

class HeaderStore {

    public menuAnchorElement: HTMLElement | undefined;

    constructor() {
        makeAutoObservable(this)
    }

    public get open(): boolean {
        return Boolean(this.menuAnchorElement);
    }

    public setMenuAnchorElement(menuAnchorElement: HTMLElement | undefined): void {
        this.menuAnchorElement = menuAnchorElement;
    }
}

const headerStore = new HeaderStore();
export default headerStore;
