import { Helmet } from 'react-helmet';
import React from 'react';
import { Liedeo } from '../../../types';

interface LiedeoHeaderProps {
    liedeo: Liedeo;
}

const PlayerHtmlHeader = (props: LiedeoHeaderProps): JSX.Element => {
    return (
        <Helmet>
            <title>{ props.liedeo.title }</title>
            <meta property="og:title" content={ props.liedeo.title }/>
            <meta property="og:description" content="Watch this mixey on mixey.rocks."/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content={ `https://mixey.rocks/player?id=${ props.liedeo.id }` }/>
            <meta property="og:image" content={ props.liedeo.images.smallUrl }/>
            <meta name="description" content="Watch this mixey on mixey.rocks."/>
        </Helmet>
    );
};

export default PlayerHtmlHeader;
