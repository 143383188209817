import React from 'react';
import { createStyles, makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { amber, green } from '@material-ui/core/colors';
import WarningIcon from '@material-ui/icons/Warning';
import { SnackbarContent } from '@material-ui/core';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        success: {
            backgroundColor: green[600],
        },
        error: {
            backgroundColor: theme.palette.error.dark,
        },
        info: {
            backgroundColor: theme.palette.primary.main,
        },
        warning: {
            backgroundColor: amber[700],
        },
        icon: {
            fontSize: 20,
        },
        iconVariant: {
            opacity: 0.9,
            marginRight: theme.spacing(1),
        },
        message: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
);

interface NotificationStoreProps {
    open: boolean;
    message: string;
    variant: 'success' | 'warning' | 'error' | 'info';
    onClose?: () => void;
    autoHideDurationMillis: number;
    additionalAction?: React.ReactNode;
}

export default function Notification(props: NotificationStoreProps): JSX.Element {
    const classes = useStyles();
    const Icon = variantIcon[props.variant];

    return (
        <Snackbar
            anchorOrigin={ {
                vertical: 'bottom',
                horizontal: 'left',
            } }
            open={ props.open }
            autoHideDuration={ props.autoHideDurationMillis }
            onClose={ props.onClose }
            ContentProps={ {
                'aria-describedby': 'message-id',
            } }
            message={ <span id="message-id">{ props.message }</span> }
        >
            <SnackbarContent
                className={ `${ classes[props.variant] }` }
                aria-describedby="client-snackbar"
                message={
                    <span id="client-snackbar" className={ classes.message }>
                        <Icon className={ `${ classes.icon } ${ classes.iconVariant } ` }/>
                        { props.message }
                    </span>
                }
                action={ [
                    props.additionalAction ?
                        (
                            <div key="action">
                                { props.additionalAction }
                            </div>
                        ) :
                        undefined,
                    <IconButton href='' key="close" aria-label="Close" color="inherit" onClick={ props.onClose }>
                        <CloseIcon className={ classes.icon }/>
                    </IconButton>,
                ] }
            />
        </Snackbar>
    );
}
