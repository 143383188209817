import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../data/stores/store-context';

const Authenticated: React.FC = observer((props) => {
    const rootStore = useRootStore();

    return rootStore.userStore.isLoggedIn ?
        <>{props.children}</> :
        null;
});

export default Authenticated;
