import * as React from 'react';
import { useEffect } from 'react';
import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import InlineLinkExternal from '../../components/links/inline-link-external';
import InlineLinkInternal from '../../components/links/inline-link-internal';
import { CONTACT_EMAIL_ADDRESS } from '../../util/content';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        content: {
            paddingTop: theme.spacing(4),
        },
    }),
);

export const PrivacyPolicy = (): JSX.Element => {
    const classes = useStyles();

    useEffect((): void => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={ classes.content }>
            <Typography component='div'>
                <Typography variant="h5">
                    Privacy Policy
                </Typography>
                <p>
                    Effective date: July 21, 2019
                </p>
                <p>
                    Your data privacy is important to us. This privacy policy explains what data we collect
                    about you, how we use it, what rights you have and how you can exercise them.
                    To summarise it quite simply at the beginning: All data we collect serves the sole purpose of providing and improving Mixey.
                    By using Mixey, you accept the collection and use of your data as described in this privacy policy.
                </p>
                <Typography variant="h6">
                    1 About this policy
                </Typography>
                <p>
                    This privacy policy ("policy") describes all aspects relevant to your data privacy on
                    https://mixey.rocks (hereinafter also referred to as "Mixey" or simply "Service").
                    Whenever this policy speaks of "we", "us" or "our", it is referring to the
                    operator of Mixey: 
                    { ' ' }
                    <InlineLinkInternal url='/legal/legal-notice'>
                        MXY Services GbR
                    </InlineLinkInternal>.
                    { ' ' }
                </p>
                <p>
                    By using Mixey you also accept the
                    { ' ' }
                    <InlineLinkInternal url='/legal/terms-of-use'>
                        Terms of Use
                    </InlineLinkInternal>
                    { ' ' }
                    which include acceptance to this privacy policy.
                </p>
                <Typography variant="h6">
                    2 Your rights
                </Typography>
                <p>
                    As user of Mixey, you have rights related to your personal data granted by the General
                    Data Protection Regulation or "GDPR". Accordingly, we give our best to provide you with
                    maximum transparency and control over your data. Except as limited under applicable
                    law, you have the following rights:
                </p>
                <ul>
                    <li><i>Access</i>: You may get access to the personal data we process about you</li>
                    <li><i>Rectification</i>: You may request us to update your personal data if captured</li>
                    inaccurate or incomplete
                    <li><i>Erasure</i>: You may request the deletion of your personal data</li>
                    <li><i>Restrict</i>: You may request that we temporarily or permanently stop processing</li>
                    your personal data
                    <li><i>Object</i>:
                        <ul>
                            <li>At any time, you can object the processing of your personal data if your
                                particular situation requires it
                            </li>
                            <li>Moreover, you may object to the use of your personal data for direct
                                marketing
                            </li>
                        </ul>
                    </li>
                    <li><i>Data portability</i>: You may request an electronic copy of your personal data and
                        may transmit it for use in another party’s service
                    </li>
                    <li><i>Right to not being a subject of automated decision-making</i>: Where an automated
                        decision or profiling would have legal (or comparable significant) impact on you, you may
                        object to such processing.
                    </li>

                </ul>
                <p>
                    If you have any questions about your privacy, your rights, or how to exercise them,
                    please <InlineLinkInternal url='/legal/legal-notice'>contact us</InlineLinkInternal>. After
                    verifying your identity, we will respond to your request in a timely matter.
                    If you are not satisfied with the way we handle your personal data, you have the right to
                    contact your have local data authority to lodge a complaint.
                </p>
                <Typography variant="h6">
                    3 Your control
                </Typography>
                <p>
                    We offer you control about the collection of your data for Google Analytics purposes via
                    the cookie consent checkbox on your profile settings page.
                    Alternatively, you can also prevent Google from collecting the data generated by the
                    cookie and relating to your use of Mixey (including your IP address) and from processing
                    this data by Google by downloading and installing the
                    { ' ' }
                    <InlineLinkExternal url="https://tools.google.com/dlpage/gaoptout?hl=en">
                        browser Add-on
                    </InlineLinkExternal>.
                    { ' ' }
                    Opt-out cookies will prevent future collection of your data when you visit this
                    website. To prevent Universal Analytics from collecting data across different devices,
                    you must opt-out on all systems used. If you click here, the opt-out cookie will be set: <DisableGoogle/>
                </p>
                <p>
                    To make use of other rights described in Chapter 2., you may reach out to
                    { ' ' }
                    <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }?subject=GDPR` }>{ CONTACT_EMAIL_ADDRESS }</a>
                    { ' ' }
                    and we will take care of it in a timely
                    manner.
                </p>
                <Typography variant="h6">
                    4 How we collect your data
                </Typography>
                <p>
                    We collect information in the following two ways: 
                </p>
                <ol>
                    <li>During sign up: When you sign-up, you may
                        <ul>
                            <li><i>E-Mail</i>: Just provide us with your E-Mail address or</li>
                            <li><i>Third-party Login</i>: Login using a third-party like Google and Facebook. Thereby you give access to
                                more personal data than just your E-Mail adress. We use the most minimal data access that 
                                the third party services offer. More info on what data is shared this way is described in the next chapter.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <li><i>Content you visit</i>: When you use Mixey, we collect data 
                            about what content you watch and how you browse through the site</li>
                        <li><i>Interactions with content</i>: In case you have a user account, we collect data abotu what content you like, favorize and create.</li> 
                        <li><i>Technical data</i>: Independent of whether you have an user account or not, we collect technical data e.g. what device you use, when you access the site using what
                            kind of connection (WLAN, 3G, etc.) to your consent.</li>
                    </li>
                </ol>
                <Typography variant="h6">
                    5 Types of data collected
                </Typography>
                <p style={{ fontWeight: 'bold' }}>
                    5.1 Account registration data
                </p>
                <p>
                    To sign you up for the service, we require some minimum amount of personal data to
                    identify you. In case you create a user account via E-Mail address we collect following
                    personal data:
                </p>
                <ul>
                    <li>E-Mail address</li>
                    <li>IP address</li>
                </ul>
                <p>
                    If you decide to login via third-party service, we may receive more personal data from
                    you when you consent to the Third Party Service (Like Google and Facebook) to share
                    your personal data with us. The data we receive in this case includes but may not be
                    limited to:
                </p>
                <ul>
                    <li>E-Mail address</li>
                    <li>Name (given name & family name)</li>
                    <li>Short name</li>
                    <li>Picture</li>
                    <li>Locale</li>
                    <li>IP address</li>
                </ul>
                <p style={{ fontWeight: 'bold' }}>
                    5.2 Usage data
                </p>
                <p>
                    Usage data is all data you provide and generate when using Mixey. That includes
                    interactions (for all users)
                </p>
                <ul>
                    <li>Date and and number of visits</li>
                    <li>Time spent on pages</li>
                    <li>Content you visit</li>
                </ul>
                <p>
                    Interactions (for logged-in users)
                </p>
                <ul>
                    <li>Content you like / favorize</li>
                    <li>Your comments</li>
                    <li>Your user name</li>
                    <li>Your Mixey posts</li>
                </ul>
                <p>
                    Technical data
                </p>
                <ul>
                    <li>URL information</li>
                    <li>Your IP address</li>
                    <li>Information about the device you use to access Mixey</li>
                    <li>Network (Connection type like WiFi, 3G, LTE, Bluetooth and provider)</li>
                    <li>Browser (Type, language, version, etc.)</li>
                    <li>Operating system</li>
                </ul>
                <p>
                    Lastly, we also store all information you provide by exchanging with the
                    { ' ' }
                    <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }` }>{ CONTACT_EMAIL_ADDRESS }</a>
                    { ' ' }
                    support E-Mail address.
                </p>
                <Typography variant="h6">
                    6. Purpose of data collection
                </Typography>
                <p>
                    We use information held about you in the following ways:
                </p>
                <ul>
                    <li><i>To personalize your experience</i>: For example, we use the name and picture you provide
                        to provide you with a recognizable account in which you can access additional functionality like
                        content creation.
                    </li>
                    <li><i>For research and development</i>: We are always looking to improve Mixey. Therefore, we
                        want to learn how people use Mixey and identify trends, usage, activity patterns and
                        areas for integration and improvement of the Services.
                    </li>
                    <li><i>Send you information about Mixey</i>: We use your contact information to send
                        you information that includes responding to your comments, questions and requests, 
                        providing customer support, and sending you technical notices, updates, security alerts, and administrative messages.
                    </li>
                    <li><i>To market, promote, and drive engagement with Mixey</i>: We use your contact
                        information and information about how you use the Services to send promotional
                        communications that may be of specific interest to you.
                    </li>
                    <li><i>Customer support</i>: We use your information to resolve technical issues you encounter, to
                        respond to your requests for assistance, to analyze crash information, and to repair and
                        improve the Services.
                    </li>
                    <li><i>For safety and security</i>: We use information about you and your activity on Mixey use to verify
                        accounts and activity, to monitor suspicious or fraudulent activity and to identify
                        violations of Service policies.
                    </li>
                </ul>
                <Typography variant="h6">
                    7 Cookies and analytics
                </Typography>
                <p>
                    This site uses cookies, which are text files placed on your computer.
                    If you have given your consent, Google Analytics, a web analysis service of Google
                    Ireland Limited is used on this website. The use includes the Universal Analytics
                    operating mode. This makes it possible to assign data, sessions and interactions across
                    multiple devices to a pseudonymous user ID and thus analyze a user's activities across
                    services.
                </p>
                <Typography variant="h6">
                    8 Duration of storage
                </Typography>
                <p>
                    The duration of storage depends on the type of data:
                </p>
                <ul>
                    <li><i>User content</i>: User content becomes Mixey’s IP according to our Terms of Service and is
                        therefore stored permanently. This does not include user content we are obliged to give you control over by law. 
                    </li>
                    <li><i>Login information</i>: Technical information (e.g. what devices you used when to
                        login, when you logged in, failed login attempts) are deleted after 2 months.
                    </li>
                    <li><i>Google analytics data</i>: The data sent by us and linked to cookies, user-
                        identifiers (e.g. User-IDs) or advertising-identifiers are automatically deleted after
                        14 months. Data whose retention period has been reached is automatically
                        deleted once a month.
                    </li>
                </ul>
                <Typography variant="h6">
                    9 Links to other sites
                </Typography>
                <p>
                    Mixey may contain links to other sites that are not operated by MXY Services GbR. If you click on a
                    third party link, you will be directed to that third party's site. We strongly advise you to
                    review the Privacy Policy of every site you visit.
                    We have no control over and assume no responsibility for the content, privacy policies or
                    practices of any third party sites or services.
                </p>
                <Typography variant="h6">
                    10 Transfer of data
                </Typography>
                <p>
                    Mixey uses certain external services which require the processing of parts of your data
                    in a different country. Your consent to this privacy policy represents your agreement to
                    following transfer:
                </p>
                <ul>
                    <li>If you are located outside Germany and choose to provide information to us,
                        please note that we transfer the data, including your personal data, to Germany and
                        process it there. Recipients of the data is
                    { ' ' }
                    <InlineLinkInternal url='/legal/legal-notice'>
                            MXY Services GbR
                    </InlineLinkInternal>.
                    </li>
                    <li>Your Login is handled via a service called 0Auth. This service sits in the USA.
                        The owner of this service is Auth0, Inc., 10800 NE 8th Street, Suite 600,
                        Bellevue, WA 98004, U.S.A. and their service manages the technical information
                        about your login. We have chosen an 0Auth usage option that forsees that Mixey's data 
                        and thereby your login data is stored and processed in an EU region. 
                    </li>
                    <li>The information generated by the Google Analytics cookies about your use of
                        this website is usually transferred to a Google server in the USA and stored there.
                        However, if IP anonymisation is activated on this website, Google will reduce your
                        IP address within Member States of the European Union or in other states party
                        to the Agreement on the European Economic Area beforehand. We would like to
                        point out that on this website Google Analytics has been extended to include IP
                        anonymisation in order to ensure anonymous collection of IP addresses (so-
                        called IP masking). The IP address transmitted by your browser in the context of
                        Google Analytics is not merged with other Google data.
                        For more information on terms of use and data protection, please visit
                    { ' ' }
                    <InlineLinkExternal
                        url="https://www.google.com/analytics/terms/gb.html">https://www.google.com/analytics/terms/gb.html</InlineLinkExternal>
                    { ' ' }
                        or
                    { ' ' }
                    <InlineLinkExternal
                        url="https://policies.google.com/?hl=en">https://policies.google.com/?hl=en</InlineLinkExternal>
                    </li>
                </ul>
                <Typography variant="h6">
                    11 Disclosure of data
                </Typography>
                <p>
                    Mixey may disclose your personal data in the good faith belief that such action is
                    necessary to:
                </p>
                <ul>
                    <li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of Mixey / MXY Services GbR</li>
                    <li>To prevent or investigate possible wrongdoing in connection with Mixey</li>
                    <li>To protect the personal safety of users of the Mixey or the public</li>
                    <li>To protect against legal liability</li>
                </ul>
                <Typography variant="h6">
                    12 Security of data
                </Typography>
                <p>
                    The security of your data is important to us, but remember that no method of
                    transmission over the internet, or method of electronic storage is 100% secure. While
                    we strive to use commercially acceptable means to protect your personal data, we
                    cannot guarantee its absolute security.
                </p>
                <p>
                    Help us to keep you safe by choosing a unique and strong password. We also recommend you to log out
                    after you are done using Mixey. If you do decide to not log
                    out, keep access to your browser and computer restricted.
                </p>
                <Typography variant="h6">
                    13 Children's privacy
                </Typography>
                <p>
                    Our Service does not address anyone under the age of 18 ("Children").
                    We do not knowingly collect personally identifiable information from anyone under the
                    age of 18. If you are a parent or guardian and you are aware that your child has
                    provided us with personal data, please contact us. If we become aware that we have
                    collected personal data from children without verification of parental consent, we take
                    steps to remove that information from our servers.
                </p>
                <Typography variant="h6">
                    14 Changes to this privacy policy
                </Typography>
                <p>
                    We may update this policy from time to time. We will notify you of any changes
                    by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes. Changes to
                    this Privacy Policy are effective when they are posted on this page.  
                    If you have an user account, we will let you know via e-mail, prior to the
                    change becoming effective. In any case we will show a prominent message on the site and update the "effective date" at the top of this privacy
                    policy.               
                </p>
                <Typography variant="h6">
                    15 Contact
                </Typography>
                <p>
                    If you have questions or concerns how we handle your data, please reach out to us:
                </p>
                <p>
                    <span style={{ display: 'block'}}>
                        MXY Services GbR
                    </span>
                    <span style={{ display: 'block'}}>
                        Halder & Dietenberger GbR
                    </span>
                    <span style={{ display: 'block'}}>
                        Siegmund-Schacky-Str. 18e
                    </span>
                    <span style={{ display: 'block'}}>
                        80993 München
                    </span>
                </p>
                <p>
                    <span style={{ display: 'block'}}>
                        Phone: +49 152 5524 0927
                    </span>
                    <span style={{ display: 'block'}}>
                        E-Mail: <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }?subject=Hello` }>{ CONTACT_EMAIL_ADDRESS }</a>
                    </span>
                </p>
            </Typography>
        </Container>
    );
}

function DisableGoogle(): JSX.Element {
    return (
        <button
            onClick={ (): void => {
                // @ts-ignore comes from google analytics
                // eslint-disable-next-line no-undef
                gaOptout();
            } }>
            Disable Google Analytics
        </button>
    );
}
