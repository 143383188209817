import React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ButtonLinkProps extends ButtonProps, RouteComponentProps {
    to: string;
}

function ButtonLink(props: ButtonLinkProps): JSX.Element {
    const { to, staticContext, match, history, location, ...rest } = props;

    return (
        <Button
            { ...rest }
            onClick={ (event): void => {
                // todo: put into own function maybe
                event.preventDefault();

                props.history.push(props.to);
                if (props.onClick) {
                    props.onClick(event);
                }
            } }
            href={ to }
        >
            { props.children }
        </Button>
    );
}

export default withRouter(ButtonLink);
