import { Helmet } from 'react-helmet';
import React from 'react';

const CreatorHtmlHeader = (): JSX.Element => {
    return (
        <Helmet>
            <title>Mixey Creator</title>
            <meta property="og:title" content="Mixey Creator"/>
            <meta property="og:description" content="Create your own mixeys on mixey.rocks."/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://mixey.rocks/creator"/>
            <meta name="description" content="Create your own mixeys on mixey.rocks."/>
        </Helmet>
    );
};

export default CreatorHtmlHeader;
