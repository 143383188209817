import { Auth0UserProfile } from 'auth0-js';

export interface Liedeo {
    id: number;
    title: string;
    images: {
        smallUrl: string;
        largeUrl: string;
    };
    amountOfFavorites: number;
    createdBy: {
        userId: string;
        username: string;
    };
    createdAt: string;
    videoId: string;
    videoTitle: string;
    videoHost: LiedeoHost;
    videoOffsetMillis: number;
    videoPlaybackSpeed: number;
    musicTitle: string;
    musicHost: LiedeoHost;
    musicId: string;
    musicOffsetMillis: number;
}

export interface LiedeoSave {
    title: string;
    videoId: string;
    videoHost: LiedeoHost;
    videoOffsetMillis: number;
    videoPlaybackSpeed: string;
    musicId: string;
    musicHost: LiedeoHost;
    musicOffsetMillis: number;
}

export type LiedeoHost = 'YOUTUBE';

export enum YoutubePlayerPlayState {
    'UNSTARTED' = -1,
    'ENDED' = 0,
    'PLAYING' = 1,
    'PAUSED' = 2,
    'BUFFERING' = 3,
    'VIDEO_CUED' = 5,
}

export interface ReactYoutubePlayer {
    seekTo(amount: number, type: 'fraction' | 'seconds'): void;

    // get number of seconds that have been player
    getCurrentTime(): number;
    getInternalPlayer(): {

        // https://developers.google.com/youtube/iframe_api_reference#Playback_status
        getPlayerState(): YoutubePlayerPlayState;
        playVideo(): void;
        pauseVideo(): void;
        stopVideo(): void;
        seekTo(seconds: number): void;
    };
}

export interface MixeyUser {
    userId: string;
    username: string;
    settings: {
        allowGoogleTracking: boolean;
    };
}

export interface User extends MixeyUser {
    email?: string;
    profilePictureUrl: string;
    isSocial: boolean;
}

export interface Auth0MixeyUserProfile extends Auth0UserProfile {
    ['https://mixey.rocks/identities']?: {
        provider: string;
        isSocial: boolean;
    }[];
}

export interface PageableRequestParams {
    page: number;
    size: number;
}

export interface PageableResponse<ContentType = unknown> {
    content: ContentType[];
    empty: boolean;
    number: number; // page number
    numberOfElements: number;
    size: number;
    totalPages: number;
    totalElements: number;
    first: boolean;
    last: boolean;
}
