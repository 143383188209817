import React, { MouseEvent, MouseEventHandler } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { StyleRules } from '@material-ui/core/styles';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import VideocamIcon from '@material-ui/icons/Videocam';
import Paper from '@material-ui/core/Paper';
import UnstyledLink from '../links/unstyled-link';
import { formatDistanceToNow } from 'date-fns';
import Box from '@material-ui/core/Box';

export const useLiedeoCardStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        root: {
            padding: theme.spacing(1),
        },
        imageContainer: {
            position: 'relative',
            overflow: 'hidden',
            width: '100%',
            paddingBottom: '56.25%',
            backgroundColor: theme.palette.action.hover,
        },
        image: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            objectPosition: 'center',
            objectFit: 'cover',
        },
        cardContentIcons: {
            marginRight: theme.spacing(1),
        },

        clickable: {
            cursor: 'pointer',
        },
        truncateText: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }),
);

interface LiedeoCardProps extends RouteComponentProps {
    id: number;
    title: string;
    smallImageUrl: string;
    largeImageUrl: string;
    videoTitle: string;
    musicTitle: string;
    createdAt: string;
    createdBy: string;
}

const LiedeoCard = (props: LiedeoCardProps): JSX.Element => {
    const styles = useLiedeoCardStyles();
    const {
        id,
        title,
        videoTitle,
        musicTitle,
        smallImageUrl,
        largeImageUrl,
        createdAt,
        createdBy,
    } = props;

    const createdAtInWords = formatDistanceToNow(new Date(Date.parse(createdAt)));
    const subtitle = `by ${ createdBy }, ${ createdAtInWords } ago`;

    const goToPlayer = (id: number): MouseEventHandler => (event: MouseEvent): void => {
        event.preventDefault();
        props.history.push(`/player?id=${ id }`);
    };

    return (
        <Paper className={ styles.clickable } onClick={ goToPlayer(id) }>
            <div className={ `${ styles.root } ${ styles.clickable }` }>
                <Box display={ 'flex' }>
                    <Box flexGrow={ 1 }>
                        <Typography
                            variant="subtitle2"
                            component="h4"
                            onClick={ goToPlayer(id) }
                            className={ styles.truncateText }
                            title={ title }
                        >
                            <UnstyledLink to={ `/player?id=${ id }` }>
                                { title }
                            </UnstyledLink>
                        </Typography>
                        <Typography
                            variant='caption'
                            className={ styles.truncateText }
                            title={ subtitle }
                        >
                            { subtitle }
                        </Typography>
                    </Box>
                </Box>
            </div>
            <div className={ styles.imageContainer }>
                <img
                    className={ styles.image }
                    alt={ 'decorative cover' }
                    src={ smallImageUrl }
                    srcSet={ `${ smallImageUrl } 1x, ${ largeImageUrl } 3x` }
                />
            </div>
            <div className={ styles.root }>
                <Box display={ 'flex' } alignItems={ 'center' }>
                    <VideocamIcon className={ styles.cardContentIcons } fontSize={ 'small' } color={ 'disabled' }/>
                    <Typography
                        component="p"
                        variant="body2"
                        className={ styles.truncateText }
                        title={ videoTitle }
                    >

                        { videoTitle }
                    </Typography>
                </Box>
                <Box display={ 'flex' } alignItems={ 'center' }>
                    <MusicNoteIcon className={ styles.cardContentIcons } fontSize={ 'small' } color={ 'disabled' }/>
                    <Typography
                        component="p"
                        variant="body2"
                        className={ styles.truncateText }
                        title={ musicTitle }
                    >

                        { musicTitle }
                    </Typography>
                </Box>
            </div>
        </Paper>
    );
};

export default withRouter(LiedeoCard);
