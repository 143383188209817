import * as React from 'react';
import { useEffect } from 'react';
import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import InlineLinkExternal from '../../components/links/inline-link-external';
import InlineLinkInternal from '../../components/links/inline-link-internal';
import { CONTACT_EMAIL_ADDRESS } from '../../util/content';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        content: {
            paddingTop: theme.spacing(4),
        },
    }),
);

export const TermsOfUse = (): JSX.Element => {
    const classes = useStyles();

    useEffect((): void => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={ classes.content }>
            <Typography component='div'>
                <Typography variant="h5" className={ classes.headlineSpacing }>
                    Terms Of Use
                </Typography>
                <p style={ { fontWeight: 'bold' } }>
                    Please read these terms carefully before using Mixey
                </p>
                <p>
                    If you use or access Mixey (the Service), you agree to accept and comply with the following
                    terms of use. Please read all terms prior to using the Service. If you do not understand a term,
                    please let us know. If you disagree with a term, do not use the Service.
                </p>
                <Typography variant="h6">
                    1 General terms
                </Typography>
                <p>
                    Mixey is controlled by <InlineLinkInternal url='/legal/legal-notice'>MXY Services GbR</InlineLinkInternal>.
                    MXY Services GbR makes no claim that the contents on Mixey are appropriate or available in any other
                    country or location.
                    Make sure that you follow your local applicable law when accessing and using Mixey.
                </p>
                <Typography variant="h6">
                    2 Use of personal data
                </Typography>
                <p>
                    All data provided to MXY Services GbR via Mixey are subject to Mixey’s <InlineLinkInternal
                    url='/legal/privacy-policy'>Privacy Policy</InlineLinkInternal>.
                </p>
                <Typography variant="h6">
                    3 Mixey’s rights to administer user access and user content
                </Typography>
                <p>
                    Mixey reserves the right, to block and unblock accounts in its own judgment that
                </p>
                <ul>
                    <li>violate any inappropriate use clause</li>
                    <li>are suspected of beeing compromised</li>
                    <li>are suspected of seeking security breaches</li>
                    <li>use the platform in a way that reduces enjoyment of Mixey for other users</li>
                </ul>
                <p>
                    Mixey also reserves the right to delete content without prior notice for reasons like
                </p>
                <ul>
                    <li>violation of the terms of use</li>
                    <li>based on MXY Services GbR's judgement that the content is of inadequate quality</li>
                </ul>
                <Typography variant="h6">
                    4 Exclusion of malicious and inappropriate usage
                </Typography>
                <p>
                    By using Mixey, you agree to not to abuse the service maliciously. The following behavior
                    is considered malicious or inappropriate use:
                </p>
                <ul>
                    <li>Spamming</li>
                    <li>Posting insulting and obscene content</li>
                    <li>Posting that includes strong, hateful, offensive language</li>
                    <li>Posting private information of any other person (e. g. names, e-mail addresses, phone numbers
                        and
                        financial information)
                    </li>
                    <li>Posting libelous, defamatory, abusive, offensive or hateful content</li>
                    <li>Posting obscene, pornographic, indecent or sexually explicit, excessive or violent content</li>
                    <li>Posting content that encourages, solicits, fosters, glorifies or provides instructions for
                        criminal or civil offense
                    </li>
                    <li>Posting content that promotes terrorism or other acts of violence;</li>
                    <li>Posting content that may infringe, misappropriate or violate any intellectual property rights,
                        rights of privacy, rights of publicity or any other rights of others
                    </li>
                    <li>Posting content or links to contents that is infected with viruses, corrupted data or other
                        harmful, disruptive or destructive files or code;
                    </li>
                    <li>Posting content that you are contractually or legally required to keep confidential</li>
                    <li>Manipulation of favorite numbers, number of hits or likes</li>
                    <li>Reverse engineering of the Mixey service</li>
                </ul>
                <Typography variant="h6">
                    5 Mixey’s property
                </Typography>
                <p>
                    Mixey and its original content, features, and functionality are owned by MXY Services GbR and
                    are protected by international copyright and other intellectual property or proprietary rights
                    laws.
                </p>
                <p>
                    If you post, store or otherwise make available content on Mixey, you grant MXY Services GbR a
                    non-exclusive, royalty-free, perpetual and fully sublicensable right and license to use,
                    reproduce, modify, create derivative works from, distribute, perform and display the
                    content for Mixey's business and marketing purposes. The main content users create on Mixey is a
                    combination of video information like video name / video id, timecodes and parameters about speed
                    and volume
                    to be used to play these videos at the same time.
                </p>
                <Typography variant="h6">
                    6 Copyright infringement removal
                </Typography>
                <p>
                    According to the Digital Millennium Copyright Act (DMCA) respective the EU Copyright
                    Directive, a service provider can be relieved from liability for copyright infringement if it
                    is promptly removed once notified by the copyright owner or a designated proxy.
                    In case a copyright owner finds that any content of this site infringes his copyright, he or
                    his proxy may request to remove the content by reaching out to <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }?subject=Copyright%20infringement%20removal` }>{ CONTACT_EMAIL_ADDRESS }</a> and by
                    providing the following information:
                </p>
                <ul>
                    <li>Electronic or physical signature</li>
                    <li>Description of the copyrighted work that is claimed as infringed</li>
                    <li>Where the infringing copyrighted work is located on Mixey</li>
                    <li>Contact information including name, address, phone number and e-mail address</li>
                    <li>A statement that you have good faith belief that the infringing use is not authorized by the
                        copyright owner, his designated agent or by law
                    </li>
                    <li>A statement under penalty of perjury that the information in the notice is accurate and that
                        it is provided by the copyright owner or an authorized agent on behalf of the copyright owner.
                    </li>
                </ul>
                <p>
                    A copyright owner or its designated agent who make misrepresentations with regards to
                    the copyright infringement may be liable for damages incurred as a result of removing or
                    blocking the alleged copyrighted work.
                </p>
                <Typography variant="h6">
                    7 Simplified copyright infringement removal
                </Typography>
                <p>
                    Almost all media content on this website is created by combining and embedding
                    Youtube videos. If you want to restrict such embedding of a video of yours, you can
                    change the permission for such embedding via YouTube. You find more information
                    about this here:
                    { ' ' }
                    <InlineLinkExternal
                        url="https://support.google.com/youtube/answer/6301625?hl=en">https://support.google.com/youtube/answer/6301625?hl=en</InlineLinkExternal>
                </p>
                <p>
                    If you just disagree with the embedding of your content on this particular site (Mixey),
                    you can use the provided report button in each video and we remove the content from
                    this site in a timely matter. In this case we ask you to validate your identity by contacting
                    the Mixey YouTube Account using the YouTube account with which the video in question was uploaded.
                </p>
                <Typography variant="h6">
                    8 Disclaimer
                </Typography>
                <p>
                    All content on this site is provided “as is”. There is no warranty of any kind like
                    intellectual property, fitness for a particular purpose, merchantability or noninfringement.
                    MXY Services GbR is not to held liable for any damages (e. g. damages for loss of profits,
                    business interruption, loss of information). MXY Services GbR does not warrant for the
                    accuracy or completeness of the contents of this site and provides no commitment to
                    continue to update the content on this site.
                </p>
                <p>
                    While we give our best to keep this page clean of infringement and inappropriate
                    content, we have to point out that we cannot guarantee to maintain a topical, accurate
                    and infringement-free state at all times. Of course, we honor our obligations to handle
                    deletion of content and manage information as per §§ 8 to 10 of the Telemedia Act (TMG).
                    We are not responsible for the external links on this website. If you visit an external link,
                    be aware that you do so at your own risk. We recommend reading the privacy policy and
                    terms of service before using the linked site. If we become aware of any legal
                    infringement related to links posted on this site, we will remove this link immediately.
                </p>
                <Typography variant="h6">
                    9 Acceptance and updates to these terms
                </Typography>
                <p>
                    MXY Services GbR will update these terms of service from time to time. If you are a registered
                    user, we will inform you 15 days prior to the update. In case you are not a registered user,
                    we do not have your e-mail address to inform you. In this case, we ask you to review these terms
                    regularly yourself. If you keep using the site after an update to the terms of use, you indicate
                    acceptance to the updated terms of use. If you do not agree with the current or with a subsequent
                    update of these terms, please
                    request the deletion of your account via <a href={ `mailto:${ CONTACT_EMAIL_ADDRESS }?subject=Account%20deletion` }>{ CONTACT_EMAIL_ADDRESS }</a>.
                    If you have no user account, stop using Mixey.
                </p>
            </Typography>
        </Container>
    );
}
