import { makeAutoObservable, runInAction } from 'mobx';
import { Liedeo, PageableRequestParams, PageableResponse } from '../../types';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import isEmpty from 'lodash/isEmpty';
import uniqWith from 'lodash/uniqWith';
import { deleteFavoriteLiedeo, fetchFavoriteLiedeos, saveFavoriteLiedeo } from '../api/favorites-api';
import { UserStore } from './user-store';
import { DEFAULT_LIEDEOS_PAGE_SIZE, pageableToArrayLength } from '../../util/request-helpers';
import { isLiedeoTheSame } from '../../util/comparator';

export class FavoriteStore {

    private userStore: UserStore;
    public favoriteLiedeos: Liedeo[] = [];
    public favoriteLiedeosState?: IPromiseBasedObservable<PageableResponse<Liedeo>>;

    public constructor(userStore: UserStore) {
        this.userStore = userStore;

        makeAutoObservable(this);
    }

    public isFavoriteLiedeo(liedeo: Liedeo): boolean {
        if (isEmpty(this.favoriteLiedeos)) {
            this.fetchFavoriteLiedeos();
        }

        return Boolean(
            !isEmpty(this.favoriteLiedeos) &&
            this.favoriteLiedeos
                .find((favoriteLiedeo): boolean => favoriteLiedeo.id === liedeo.id),
        );
    }

    public fetchFavoriteLiedeos(params: PageableRequestParams = { page: 0, size: DEFAULT_LIEDEOS_PAGE_SIZE }): void {
        if (!this.userStore.isLoggedIn) {
            return;
        }

        const expectedAmountOfLiedeos = pageableToArrayLength(params);
        if (!isEmpty(this.favoriteLiedeos) && this.favoriteLiedeos.length >= expectedAmountOfLiedeos) {
            return;
        }

        this.favoriteLiedeosState = fromPromise<PageableResponse<Liedeo>>(
            fetchFavoriteLiedeos(params)
                .then((pagedLiedeos: PageableResponse<Liedeo>): PageableResponse<Liedeo> => {
                    return runInAction((): PageableResponse<Liedeo> => {
                        if (!pagedLiedeos.empty) {
                            this.favoriteLiedeos = uniqWith([...this.favoriteLiedeos, ...pagedLiedeos.content], isLiedeoTheSame);
                        }
                        return pagedLiedeos;
                    });
                }),
        );
    }

    public saveFavoriteLiedeo(liedeo: Liedeo): void {
        saveFavoriteLiedeo(liedeo.id)
            .then((): void => {
                return runInAction((): void => {
                    this.favoriteLiedeos.push(liedeo);
                    liedeo.amountOfFavorites = liedeo.amountOfFavorites + 1;
                });
            });
    }

    public deleteFavoriteLiedeo(liedeo: Liedeo): void {
        deleteFavoriteLiedeo(liedeo.id)
            .then((): void => {
                return runInAction((): void => {
                    this.favoriteLiedeos = this.favoriteLiedeos
                        .filter((favoriteLiedeo): boolean => favoriteLiedeo.id !== liedeo.id);
                    liedeo.amountOfFavorites = liedeo.amountOfFavorites - 1;
                });
            });
    }
}
