import React, { MouseEvent, PureComponent } from 'react';
import { Button } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

interface SuccessActionNotificationButtonProps extends RouteComponentProps {
    liedeoId: number;
    onClick: () => void;
}

class SuccessActionNotificationButton extends PureComponent<SuccessActionNotificationButtonProps> {

    public render(): JSX.Element {
        return (
            <Button
                href={this.getPlayerPath()}
                onClick={this.goToPlayer}
                variant='outlined'
                color="inherit"
            >
                View
            </Button>
        );
    }

    private goToPlayer = (event: MouseEvent): void => {
        event.preventDefault();

        this.props.history.push(this.getPlayerPath());
        this.props.onClick();
    }

    private getPlayerPath = (): string => {
        return `/player?id=${ this.props.liedeoId }`;
    }
}

export default withRouter(SuccessActionNotificationButton);

