import auth0, { Auth0DecodedHash } from 'auth0-js';
import { Auth0MixeyUserProfile } from '../../types';

const AUDIENCE = 'https://api.mixey.rocks';

class Auth {
    private auth0 = new auth0.WebAuth({
        domain: 'mixey.eu.auth0.com',
        clientID: '0juchQfD9m7nRvrDfWm9SpfcB3HPUyG7',
        redirectUri: `${window.location.origin}/login-callback`,
        responseType: 'token id_token',
        scope: 'openid profile email',
        audience: AUDIENCE,
    });

    public login(): void {
        this.auth0.authorize({
            mode: 'login',
        });
    }

    public register(): void {
        this.auth0.authorize({
            mode: 'signUp',
        });
    }

    public fetchProfile(accessToken: string): Promise<Auth0MixeyUserProfile> {
        return new Promise(((resolve, reject): void => {
            this.auth0.client.userInfo(accessToken, (err, profile): void => {
                if (profile) {
                    resolve(profile);
                }
                reject(err);
            });
        }));
    }

    public parseAuthenticationUrl(): Promise<Auth0DecodedHash> {
        return new Promise((resolve, reject): void => {
            this.auth0.parseHash((err, authResult): void => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    resolve(authResult);
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    /**
     * Also called "silent auth".
     */
    public renewSession(): Promise<Auth0DecodedHash> {
        return new Promise((resolve, reject): void => {
            this.auth0.checkSession({}, (err, authResult): void => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    resolve(authResult);
                } else if (err) {
                    reject(err);
                }
            });
        });
    }

    public logout(): void {
        this.auth0.logout({
            returnTo: window.location.origin,
        });
    }

    public triggerPasswordChangeEmail(email: string): Promise<void> {
        return new Promise((resolve, reject): void => {
            this.auth0.changePassword({
                connection: 'Username-Password-Authentication',
                email: email,
            }, (error): void => {
                if (error) {
                    console.error(error);

                    reject(error);
                }
                resolve();
            });
        })
    }
}

const auth = new Auth();
export default auth;
