import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import NotFound from '../404/404';
import FeedTop from './feed-top';
import FeedLatest from './feed-latest';
import FeedFavorites from './feed-favorites';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

export const useFeedStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        header: {
            paddingTop: theme.spacing(4),
        },
        row: {
            [theme.breakpoints.only('xs')]: {
                paddingBottom: theme.spacing(2),
            },
            [theme.breakpoints.up('sm')]: {
                paddingBottom: theme.spacing(5),
            },
        },
    }),
);

const Feed = (props: RouteComponentProps): JSX.Element => {
    useEffect((): void => {
        // if on the previous route, the users scrolled down, he is down also on this page.
        // we therefore need to scroll up manually
        window.scrollTo(0, 0);
    });

    return (
        <Switch>
            <Route path={ `${ props.match.path }/top` } component={ FeedTop }/>
            <Route path={ `${ props.match.path }/latest` } component={ FeedLatest }/>
            <Route path={ `${ props.match.path }/favorites` } component={ FeedFavorites }/>
            <Route component={ NotFound }/>
        </Switch>
    );
};

export default withRouter(Feed);
