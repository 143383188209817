import React from 'react';
import Home from './home/home';
import Player from './player/player';
import Creator from './creator/creator';
import LoginCallback from './login/login-callback';
import ProfileSettings from './profile-settings/profile-settings';
import { LegalNotice } from './legal/legal-notice';
import { PrivacyPolicy } from './legal/privacy-policy';
import { TermsOfUse } from './legal/terms-of-use';
import Header from '../components/header/header';
import { Footer } from '../components/footer/footer';
import NotFound from './404/404';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BridgeProvider } from '../data/stores/store-context';
import { createStyles, CssBaseline, makeStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import NotificationProvider from '../components/notification/notification-provider';
import Feed from './feed/feed';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Helmet } from 'react-helmet';
import EmailNotVerifiedDialog from '../components/authenticated/email-not-verified-dialog';

const useStyles = makeStyles((): StyleRules =>
    createStyles({
        '@global': {
            'html, body, #root': {
                height: '100%',
            },
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        content: {
            flex: '1 0 auto',
        },
        footer: {
            flexShrink: 0,
        },
    }),
);

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4E3E84' },
        background: { default: '#FFF' },
    },
});

export const App = (): JSX.Element => {
    const classes = useStyles();

    return (
        <ThemeProvider theme={ theme }>
            <Helmet>
                <title>Mixey</title>
                <meta property="og:title" content="Mixey"/>
                <meta property="og:description" content="Watch the best video mashups on mixey.rocks."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content="https://mixey.rocks"/>
                <meta name="description" content="Watch the best video mashups on mixey.rocks."/>
                <meta name="theme-color" content="#4E3E84"/>
            </Helmet>
            <CssBaseline/>
            <Router basename={ `${ process.env.PUBLIC_URL }/` }>
                <BridgeProvider>
                    <div className={ classes.root }>
                        <Header/>
                        <div className={ classes.content }>
                            <Switch>
                                <Route exact path="/" component={ Home }/>
                                <Route exact path='/login-callback' component={ LoginCallback }/>
                                <Route path="/player" component={ Player }/>
                                <Route path="/feed" component={ Feed }/>
                                <Route path="/creator" component={ Creator }/>
                                <Route path="/profile-settings" component={ ProfileSettings }/>
                                <Route path="/legal/legal-notice" component={ LegalNotice }/>
                                <Route path="/legal/privacy-policy" component={ PrivacyPolicy }/>
                                <Route path="/legal/terms-of-use" component={ TermsOfUse }/>
                                <Route component={ NotFound }/>
                            </Switch>
                        </div>
                        <div className={ classes.footer }>
                            <Footer/>
                        </div>
                    </div>
                    <NotificationProvider/>
                    <EmailNotVerifiedDialog/>
                </BridgeProvider>
            </Router>
        </ThemeProvider>
    );
}

