import { PageableRequestParams } from '../types';

export const DEFAULT_LIEDEOS_PAGE_SIZE = 12;

/**
 * Calculate the expected array size by the given page params.
 *
 * @param params
 */
export function pageableToArrayLength(params?: PageableRequestParams): number {
    if (!params) {
        return DEFAULT_LIEDEOS_PAGE_SIZE;
    }

    const multiplier = params.page + 1;
    return params.size * multiplier;
}
