import { makeAutoObservable, runInAction } from 'mobx';
import {
    fetchLatestLiedeos,
    fetchLiedeo,
    fetchRecommendedByMixeyLiedeos,
    fetchTopLiedeos,
    saveLiedeo,
} from '../api/liedeo-api';
import { Liedeo, LiedeoSave, PageableRequestParams, PageableResponse } from '../../types';
import isEmpty from 'lodash/isEmpty';
import uniqWith from 'lodash/uniqWith';
import { fromPromise, IPromiseBasedObservable } from 'mobx-utils';
import { DEFAULT_LIEDEOS_PAGE_SIZE, pageableToArrayLength } from '../../util/request-helpers';
import { isLiedeoTheSame } from '../../util/comparator';

export class LiedeoStore {

    public liedeos: Liedeo[] = [];
    public topLiedeos: Liedeo[] = [];
    public latestLiedeos: Liedeo[] = [];
    public recommendedByMixeyLiedeos: Liedeo[] = [];

    public fetchLiedeoState: Map<string, IPromiseBasedObservable<void>> = new Map();
    public fetchTopLiedeosState?: IPromiseBasedObservable<PageableResponse<Liedeo>>;
    public fetchLatestLiedeosState?: IPromiseBasedObservable<PageableResponse<Liedeo>>;
    public fetchRecommendedByMixeyLiedeosState?: IPromiseBasedObservable<void>;

    constructor() {
        makeAutoObservable(this)
    }

    public fetchLiedeo(id: number): void {
        if (!isEmpty(this.liedeos)) {
            const liedeo: Liedeo | undefined = this.liedeos.find((liedeo): boolean => {
                return liedeo.id === id
            });

            if (liedeo) {
                if (!this.fetchLiedeoState.get(liedeo.id.toString())) {
                    this.fetchLiedeoState.set(liedeo.id.toString(), fromPromise.resolve());
                }
                return;
            }
        }

        this.fetchLiedeoState.set(id.toString(), fromPromise(
            fetchLiedeo(id)
                .then((liedeo: Liedeo): void => {
                    return runInAction((): void => {
                        this.liedeos.push(liedeo);
                    });
                }),
        ));
    }

    public fetchTopLiedeos(params: PageableRequestParams = { page: 0, size: DEFAULT_LIEDEOS_PAGE_SIZE }): void {
        const expectedAmountOfLiedeos = pageableToArrayLength(params);
        if (!isEmpty(this.topLiedeos) && this.topLiedeos.length >= expectedAmountOfLiedeos) {
            return;
        }

        this.fetchTopLiedeosState = fromPromise<PageableResponse<Liedeo>>(
            fetchTopLiedeos(params)
                .then((pagedLiedeos: PageableResponse<Liedeo>): PageableResponse<Liedeo> => {
                    return runInAction((): PageableResponse<Liedeo> => {
                        if (!pagedLiedeos.empty) {
                            this.topLiedeos = uniqWith([...this.topLiedeos, ...pagedLiedeos.content], isLiedeoTheSame);
                            this.liedeos = uniqWith([...this.liedeos, ...pagedLiedeos.content], isLiedeoTheSame);
                        }
                        return pagedLiedeos;
                    });
                }),
        );
    }

    public fetchLatestLiedeos(params: PageableRequestParams = { page: 0, size: DEFAULT_LIEDEOS_PAGE_SIZE }): void {
        const expectedAmountOfLiedeos = pageableToArrayLength(params);
        if (!isEmpty(this.latestLiedeos) && this.latestLiedeos.length >= expectedAmountOfLiedeos) {
            return;
        }

        this.fetchLatestLiedeosState = fromPromise<PageableResponse<Liedeo>>(
            fetchLatestLiedeos(params)
                .then((pagedLiedeos: PageableResponse<Liedeo>): PageableResponse<Liedeo> => {
                    return runInAction((): PageableResponse<Liedeo> => {
                        if (!pagedLiedeos.empty) {
                            this.latestLiedeos = uniqWith([...this.latestLiedeos, ...pagedLiedeos.content], isLiedeoTheSame);
                            this.liedeos = uniqWith([...this.liedeos, ...pagedLiedeos.content], isLiedeoTheSame);
                        }
                        return pagedLiedeos;
                    });
                }),
        );
    }

    public fetchRecommendedByMixeyLiedeos(): void {
        if (!isEmpty(this.recommendedByMixeyLiedeos)) {
            return;
        }

        this.fetchRecommendedByMixeyLiedeosState = fromPromise(
            fetchRecommendedByMixeyLiedeos()
                .then((liedeos: Liedeo[]): void => {
                    return runInAction((): void => {
                        this.recommendedByMixeyLiedeos = liedeos;
                        this.liedeos = uniqWith([...this.liedeos, ...liedeos], (liedeoA, liedeoB) => liedeoA.id === liedeoB.id);
                    });
                }),
        );
    }

    public saveLiedeo(liedeoSave: LiedeoSave): Promise<Liedeo> {
        return saveLiedeo(liedeoSave)
            .then((liedeo): Liedeo => {
                runInAction((): void => {
                    this.latestLiedeos.push(liedeo);
                });
                return liedeo;
            });
    }

}

const liedeoStore = new LiedeoStore();
export default liedeoStore;

