import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import LiedeoCardsRow from '../../components/liedeo-cards-row';
import { useRootStore } from '../../data/stores/store-context';
import isEmpty from 'lodash/isEmpty';
import { observer } from 'mobx-react';
import useLiedeoCardsPerRow from '../../util/hooks/useLiedeoCardsPerRow';
import { useHomeStyles } from './home';
import { SeeAllMobileButton } from './components/see-all-mobile-button';
import { HomeLiedeosHeader } from './components/home-liedeos-header';

const HomeFavorites = observer((): JSX.Element | null => {
    const styles = useHomeStyles();

    return (
        <div className={ styles.liedeoRow }>
            <HomeLiedeosHeader type={'favorites'}/>
            <Liedeos/>
        </div>
    );
});

const Liedeos = observer((): JSX.Element => {
    const rootStore = useRootStore();

    useEffect((): void => {
        rootStore.favoriteStore.fetchFavoriteLiedeos();
    }, [rootStore.favoriteStore]);

    const liedeoCardsPerRow = useLiedeoCardsPerRow();
    const liedeos = rootStore.favoriteStore.favoriteLiedeos.slice(0, liedeoCardsPerRow);
    const isLoading = !rootStore.favoriteStore.favoriteLiedeosState || rootStore.favoriteStore.favoriteLiedeosState.state === 'pending';

    if (rootStore.favoriteStore.favoriteLiedeosState && rootStore.favoriteStore.favoriteLiedeosState.state === 'rejected') {
        return <div>Error. Please try again...</div>;
    }

    return (
        <>
            <Grid
                item
                xs={ 12 }
            >
                {
                    isLoading ?
                        <LiedeoCardsRow loading={ isLoading } liedeos={ liedeos }/> :
                        isEmpty(liedeos) ?
                            <div>Favorite Mixes you like and see them here</div> :
                            <LiedeoCardsRow liedeos={ liedeos }/>
                }
            </Grid>
            {
                !isLoading && !isEmpty(liedeos) ? <SeeAllMobileButton type={'favorites'}/> : undefined
            }
        </>

    );
});

export default HomeFavorites;
