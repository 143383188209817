import { useTheme } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

// according to the docs, this hook could be dangerous (if the amount of breakpoints changes)
// should be checked from time to time if there is a better option available
// https://material-ui.com/components/use-media-query/#migrating-from-withwidth
export default function useWidth(): Breakpoint {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
        keys.reduce((output: Breakpoint | null, key) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key));
            return !output && matches ? key : output;
        }, null) || 'xs'
    );
}
