import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '../../../components/links/button-link';
import React from 'react';
import { HomeLiedeoTypes, useHomeStyles } from '../home';

export interface SeeAllMobileButtonProps {
    type: HomeLiedeoTypes;
}

export function SeeAllMobileButton(props: SeeAllMobileButtonProps): JSX.Element {
    const styles = useHomeStyles();

    let text = '';
    let link = '';

    switch (props.type) {
        case 'top':
            text = 'See all top';
            link = '/feed/top';
            break;
        case 'latest':
            text = 'See all latest';
            link = '/feed/latest';
            break;
        case 'favorites':
            text = 'See all favorites';
            link = '/feed/favorites';
            break;

    }

    return (
        <Hidden smUp={ true }>
            <Grid
                container
                justify='center'
                alignItems='center'
                className={ styles.seeLatestMobileButton }
            >
                <ButtonLink
                    to={ link }
                    color={ 'primary' }
                    variant={ 'outlined' }
                >
                    { text }
                </ButtonLink>
            </Grid>
        </Hidden>
    );
}
