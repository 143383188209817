import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useLiedeoCardStyles } from './liedeo-card';
import Paper from '@material-ui/core/Paper';

export const LiedeoCardSkeleton: React.FC = () => {
    const styles = useLiedeoCardStyles();

    return (
        <Paper>
            <div className={ styles.root }>
                <Skeleton variant={ 'text' } style={ { marginTop: 0, marginBottom: 6, height: '22px' } }/>
                <Skeleton variant={ 'text' } style={ { marginBottom: 0, marginTop: 6, height: '14px' } }/>
            </div>
            <div className={ styles.imageContainer }/>
            <div className={ styles.root }>
                <Skeleton variant={ 'text' } style={ { marginTop: 0, marginBottom: 6, height: '17px' } }/>
                <Skeleton variant={ 'text' } style={ { marginBottom: 0, marginTop: 6, height: '17px' } }/>
            </div>
        </Paper>
    );
};
