import React from 'react';
import { createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import LiedeoCard from '../liedeo-card/liedeo-card';
import { Liedeo } from '../../types';
import { StyleRules } from '@material-ui/core/styles';
import { GridSize } from '@material-ui/core/Grid';
import { LiedeoCardSkeleton } from '../liedeo-card/liedeo-card-skeleton';
import useLiedeoCardsPerRow from '../../util/hooks/useLiedeoCardsPerRow';
import times from 'lodash/times';

export const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        card: {
            [theme.breakpoints.only('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                minWidth: '200px',
                flexBasis: '0',
                flex: '1 1 0',
            },
        },
    }),
);

interface LiedeoCardsRowProps {
    liedeos?: Liedeo[];
    loading?: boolean;
}

const LiedeoCardsRow = (props: LiedeoCardsRowProps): JSX.Element => {
    const {
        liedeos,
        loading,
    } = props;
    const styles = useStyles();

    // needs to have a size to fit all liedeos in one row
    const liedeoCardsPerRow = useLiedeoCardsPerRow();
    const gridSizePerLiedeo: GridSize = (12 / liedeoCardsPerRow) as GridSize;


    return (
        <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={ 2 }
        >
            {
                loading ?
                    times(4, (index): JSX.Element => {
                        return (
                            <Grid
                                item
                                sm={ gridSizePerLiedeo }
                                key={ index }
                                className={ styles.card }
                            >
                                <LiedeoCardSkeleton/>
                            </Grid>
                        );
                    }) :
                    liedeos!.map((liedeo: Liedeo): JSX.Element => {
                        return (
                            <Grid
                                item
                                sm={ gridSizePerLiedeo }
                                key={ liedeo.id }
                                className={ styles.card }
                            >
                                <LiedeoCard
                                    id={ liedeo.id }
                                    title={ liedeo.title }
                                    smallImageUrl={ liedeo.images.smallUrl }
                                    largeImageUrl={ liedeo.images.largeUrl }
                                    videoTitle={ liedeo.videoTitle }
                                    musicTitle={ liedeo.musicTitle }
                                    createdAt={ liedeo.createdAt }
                                    createdBy={ liedeo.createdBy.username }
                                />
                            </Grid>
                        );
                    }) }
        </Grid>
    );
};

export default LiedeoCardsRow;
