import * as React from 'react';
import liedeoStore, { LiedeoStore } from './liedeo-store';
import userStore, { UserStore } from './user-store';
import { FavoriteStore } from './favorite-store';
import notificationStore, { NotificationStore } from '../../components/notification/store';

const favoriteStore = new FavoriteStore(userStore);

export interface ContextStores {
    liedeoStore: LiedeoStore;
    userStore: UserStore;
    favoriteStore: FavoriteStore;
    notificationStore: NotificationStore;
}

const INITIAL_CONTEXT: ContextStores = {
    liedeoStore: liedeoStore,
    userStore: userStore,
    favoriteStore: favoriteStore,
    notificationStore: notificationStore,
};

export const RootContext = React.createContext<ContextStores>(INITIAL_CONTEXT);

export const BridgeProvider: React.FC = (props) => (
    <RootContext.Provider
        value={INITIAL_CONTEXT}
    >
        {props.children}
    </RootContext.Provider>
);

export const useRootStore = (): ContextStores => {
    return React.useContext(RootContext);
};
