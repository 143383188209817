import { Link } from 'react-router-dom';
import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';

const useStyles = makeStyles((): StyleRules =>
    createStyles({
        nonDecorated: {
            textDecoration: 'none',
            color: 'inherit',
            '&:visited': {
                color: 'inherit',
            },
        },
    }),
);

interface UnstyledLinkProps {
    to: string;
    replace?: boolean;
    target?: string;
    children?: React.ReactNode;
}

function UnstyledLink(props: UnstyledLinkProps): JSX.Element {
    const classes = useStyles();

    return (
        <Link
            to={ props.to }
            replace={ props.replace }
            target={ props.target }
            className={ classes.nonDecorated }
        >
            { props.children }
        </Link>
    );
}

export default UnstyledLink;
