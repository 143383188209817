import React from 'react';
import { Container, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import TypographyLink from '../links/typography-link';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
        },
        content: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            color: theme.palette.text.secondary,
        },
        contentGrid: {
            flexDirection: 'row',
            [theme.breakpoints.only('xs')]: {
                flexDirection: 'column',
            },
        },
        contentGridItem: {
            [theme.breakpoints.only('xs')]: {
                marginBottom: theme.spacing(1),
            },
        },
        spacingRight: {
            marginRight: theme.spacing(3),
        },
    }),
);

export const Footer = (): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={ classes.root }>
            <Container
                maxWidth='lg'
                className={ classes.content }
            >
                <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    className={ classes.contentGrid }
                >
                    <div className={ classes.contentGridItem }>
                        <TypographyLink
                            to='/legal/legal-notice'
                            variant={ 'body2' }
                            display='inline'
                            className={ classes.spacingRight }
                        >
                            Legal
                        </TypographyLink>
                        <TypographyLink
                            to='/legal/terms-of-use'
                            variant={ 'body2' }
                            display='inline'
                            className={ classes.spacingRight }
                        >
                            Terms
                        </TypographyLink>
                        <TypographyLink
                            to='/legal/privacy-policy'
                            variant={ 'body2' }
                            display='inline'
                        >
                            Privacy
                        </TypographyLink>
                    </div>
                    <div className={ classes.contentGridItem }>
                        <Typography variant={ 'body2' } display='inline'>
                            © 2022 Mixey
                        </Typography>
                    </div>
                </Grid>
            </Container>
        </div>
    );
}
