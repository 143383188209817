import TagManager from 'react-gtm-module'

class Tracking {

    public isEnabled = true;

    public initialize(): void {
        if (this.shouldTrack()) {
            console.log('tracking: enabled...');
            TagManager.initialize({
                gtmId: 'GTM-WNSLV5M',
            });
        } else {
            console.log('tracking: disabled...');
        }
    }

    private shouldTrack(): boolean {
        return this.isEnabled &&
            process.env.NODE_ENV === 'production';
    }
}

const tracking = new Tracking();
export default tracking;
