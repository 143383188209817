import React, { PureComponent } from 'react';
import ReactPlayer from 'react-player';
import { Container, Grid, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { extractLiedeoIdFromUrl } from '../../util/lideo-url-extractor';
import { StyleRules } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { formatDistanceToNow } from 'date-fns';
import { Liedeo, ReactYoutubePlayer, YoutubePlayerPlayState } from '../../types';
import { RootContext } from '../../data/stores/store-context';
import { PlayerStore } from './player-store';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Divider from '@material-ui/core/Divider';
import PlayerHtmlHeader from './components/player-html-header';
import PlayerActions from './components/player-actions';
import { MixeyActions } from './components/mixey-actions';

const styles = (theme: Theme): StyleRules => ({
    // video
    liedeoVideoRow: {
        backgroundColor: '#000',
    },
    liedeoVideoAutoHeight: {
        position: 'relative',
        overflow: 'hidden',
        paddingTop: '56.25%', // 16:9
    },

    // action bar
    actionBar: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
    },

    // title bar
    titleBar: {
        marginBottom: theme.spacing(2),
    },

    // info box
    youtubeInfoBox: {
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
    },
    youtubeInfoBoxLink: {
        textDecoration: 'none',
        color: 'inherit',
        '&:visited': {
            color: 'inherit',
        },
    },
    youtubeInfoBoxLinkText: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    youtubeInfoBoxLinkIcon: {
        marginLeft: theme.spacing(1),
    },

    // general
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    fullWithContainerMd: {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    bodyHeadline: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
});

const YoutubeInfoBox = (props: { type: 'video' | 'music'; title: string; id: string }): JSX.Element => {
    const classes = makeStyles(styles)();

    return (
        <div>
            <Typography className={ classes.bodyHeadline }>
                { props.type === 'video' ? 'Video' : 'Music' }
            </Typography>
            <Typography>
                { props.title }
            </Typography>
            <a
                className={ classes.youtubeInfoBoxLink }
                href={ `https://www.youtube.com/watch?v=${ props.id }` }
                target='_blank'
                rel="noopener noreferrer"
            >
                <Typography color='textSecondary' className={ classes.youtubeInfoBoxLinkText }>Youtube
                    <OpenInNewIcon fontSize={ 'small' } className={ classes.youtubeInfoBoxLinkIcon }/>
                </Typography>
            </a>
        </div>
    );
};

type PlayerProps = WithStyles<typeof styles>;

@observer
class Player extends PureComponent<PlayerProps> {

    public static contextType = RootContext;
    public context!: React.ContextType<typeof RootContext>;

    private playerStore: PlayerStore;
    private youtubeVideoPlayer?: ReactYoutubePlayer;
    private youtubeMusicPlayer?: ReactYoutubePlayer;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private youtubeVideoPlayerRef = (player: any): void => {
        this.youtubeVideoPlayer = player;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private youtubeMusicPlayerRef = (player: any): void => {
        this.youtubeMusicPlayer = player;
    };

    public constructor(props: PlayerProps) {
        super(props);

        this.playerStore = new PlayerStore();
    }

    public componentDidMount(): void {
        // if on the previous route, the users scrolled down, he is down also on this page.
        // we therefore need to scroll up manually
        window.scrollTo(0, 0);

        const liedeoId = extractLiedeoIdFromUrl();
        this.context.liedeoStore.fetchLiedeo(liedeoId!);
    }

    public componentWillUnmount(): void {
        this.playerStore.pauseMusic();
        this.playerStore.pauseVideo();
    }

    public render(): JSX.Element {
        // todo: catch errors (like missing or wrong id)
        const liedeoId = extractLiedeoIdFromUrl() || '';
        const fetchLiedeoState = this.context.liedeoStore.fetchLiedeoState.get(liedeoId.toString());

        console.log('FABI LIEDEO', liedeoId);
        console.log('FABI LIEDEO STATE', fetchLiedeoState);

        if (!fetchLiedeoState || fetchLiedeoState.state !== 'fulfilled') {
            return <div>Loading...</div>;
        }

        const liedeo = this.getLiedeo();
        const { classes } = this.props;

        const createdAtInWords = formatDistanceToNow(new Date(Date.parse(liedeo.createdAt)));

        return (
            <>
                <PlayerHtmlHeader liedeo={ liedeo }/>
                <Container
                    maxWidth={ false }
                    className={ `${ classes.liedeoVideoRow } ${ classes.fullWithContainerMd }` }
                >
                    <Container
                        maxWidth='lg'
                        className={ classes.fullWithContainerMd }
                    >
                        <Grid
                            item
                            xs={ 12 }
                        >
                            <div className={ classes.liedeoVideoAutoHeight }>
                                <ReactPlayer
                                    url={ `https://youtu.be/${ liedeo.videoId }&t=${ liedeo.videoOffsetMillis / 1000 }s` }
                                    ref={ this.youtubeVideoPlayerRef }
                                    onReady={ this.onVideoReady }
                                    onBuffer={ this.onVideoBuffer }
                                    onPlay={ this.onVideoPlay }
                                    onPause={ this.onVideoPause }
                                    volume={ 0 }
                                    style={ {
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        border: '0',
                                    } }
                                    width={ '100%' }
                                    height={ '100%' }
                                    config={ {
                                        youtube: {
                                            playerVars: {
                                                fs: 1,
                                                origin: window.location.origin,
                                            },
                                        },
                                    } }
                                    playsinline={ true }
                                    controls={ false }
                                    muted={ true }
                                />
                            </div>
                        </Grid>
                    </Container>
                </Container>
                <Container
                    maxWidth='lg'
                >
                    <Grid container justify='space-between' className={ classes.actionBar }>
                        <PlayerActions
                            playerStore={this.playerStore}
                            onStop={this.stop}
                            onSync={this.sync}
                            onTogglePlay={this.togglePlay}
                        />
                        <MixeyActions
                            playerStore={this.playerStore}
                            liedeo={liedeo}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant={ 'h5' }>
                            { liedeo.title }
                        </Typography>
                        <Typography display='inline'>
                            by { `${ liedeo.createdBy.username }, ${ createdAtInWords }` } ago
                        </Typography>
                    </Grid>
                    <Divider className={ classes.divider }/>
                    <Grid container>
                        <Grid item xs={ 12 } sm={ 6 } md={ 3 } className={ classes.youtubeInfoBox }>
                            <YoutubeInfoBox
                                type={ 'video' }
                                title={ liedeo.videoTitle }
                                id={ liedeo.videoId }
                            />
                        </Grid>
                        <Grid item xs={ undefined } md={ 1 }/>
                        <Grid item xs={ 12 } sm={ 6 } md={ 3 } className={ classes.youtubeInfoBox }>
                            <YoutubeInfoBox
                                type={ 'music' }
                                title={ liedeo.musicTitle }
                                id={ liedeo.musicId }
                            />
                        </Grid>
                        <Grid item xs={ undefined } md={ 1 }/>
                        <Grid item xs={ 12 } md={ 4 }>
                            <div className={ classes.liedeoVideoAutoHeight }>
                                <ReactPlayer
                                    url={ `https://youtu.be/${ liedeo.musicId }&t=${ liedeo.musicOffsetMillis / 1000 }s` }
                                    ref={ this.youtubeMusicPlayerRef }
                                    volume={ 100 }
                                    style={ {
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                    } }
                                    width={ '100%' }
                                    height={ '100%' }
                                    onReady={ this.onMusicReady }
                                    onEnded={ this.onEnded }
                                    config={ {
                                        youtube: {
                                            playerVars: {
                                                fs: 0,
                                                origin: window.location.origin,
                                            },
                                        },
                                    } }
                                    controls={ false }
                                    playsinline={ true }
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </>
        );
    }

    private togglePlay = (): void => {
        if (this.playerStore.isPlayingMusic || this.playerStore.isPlayingVideo) {
            this.youtubeRefsPause();

            this.playerStore.pauseVideo();
            this.playerStore.pauseMusic();
        } else {
            this.youtubeRefsPlay();

            this.playerStore.playVideo();
            this.playerStore.playMusic();
        }
    };

    private stop = (): void => {
        const liedeo = this.getLiedeo();

        this.youtubeRefsPause();

        this.youtubeVideoPlayer!.getInternalPlayer().seekTo(liedeo.videoOffsetMillis / 1000);
        this.youtubeMusicPlayer!.getInternalPlayer().seekTo(liedeo.musicOffsetMillis / 1000);
    };

    private sync = (): void => {
        const liedeo = this.getLiedeo();
        const videoSeconds = this.youtubeVideoPlayer!.getCurrentTime() < liedeo.videoOffsetMillis / 1000 ?
            liedeo.videoOffsetMillis / 1000 :
            this.youtubeVideoPlayer!.getCurrentTime();

        const videoPlayedSeconds = videoSeconds - (liedeo.videoOffsetMillis / 1000);
        const musicPlayedSeconds = (liedeo.musicOffsetMillis / 1000) + videoPlayedSeconds;

        this.youtubeVideoPlayer!.getInternalPlayer().seekTo(videoSeconds);
        this.youtubeMusicPlayer!.getInternalPlayer().seekTo(musicPlayedSeconds);
    };

    private onVideoReady = (): void => {
        this.playerStore.setIsVideoReady(true);
    };

    private onMusicReady = (): void => {
        this.playerStore.setIsMusicReady(true);
    };

    private onVideoBuffer = (): void => {
        // used to start video and music more in sync
        this.youtubeMusicPlayer!.getInternalPlayer().playVideo();
    };

    private onVideoPlay = (): void => {
        this.playerStore.playVideo();
        this.playerStore.playMusic();

        this.youtubeRefsPlay();
    };

    private onVideoPause = (): void => {
        this.playerStore.pauseVideo();
        this.playerStore.pauseMusic();

        this.youtubeRefsPause();
    };

    private onEnded = (): void => {
        this.stop();
    };

    private youtubeRefsPause(): void {
        this.youtubeVideoPlayer!.getInternalPlayer().pauseVideo();
        this.youtubeMusicPlayer!.getInternalPlayer().pauseVideo();
    }

    private youtubeRefsPlay(): void {
        this.youtubeVideoPlayer!.getInternalPlayer().playVideo();
        this.youtubeMusicPlayer!.getInternalPlayer().playVideo();
    }

    private isPlayingFromRef(): boolean {
        if (!this.youtubeVideoPlayer || !this.youtubeMusicPlayer) {
            return false;
        }

        const videoPlayState = this.youtubeVideoPlayer.getInternalPlayer().getPlayerState();
        const musicPlayState = this.youtubeMusicPlayer.getInternalPlayer().getPlayerState();

        return videoPlayState === YoutubePlayerPlayState.PLAYING || musicPlayState === YoutubePlayerPlayState.PLAYING;
    }

    private getLiedeo(): Liedeo {
        const liedeoId = extractLiedeoIdFromUrl();
        return this.context.liedeoStore.liedeos.find((liedeo): boolean => Number(liedeo.id) === Number(liedeoId))!;
    }
}

export default withStyles(styles)(Player);
