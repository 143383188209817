import useWidth from './useWidth';

export type LiedeoCardsPerRow = 2 | 4;

export default function useLiedeoCardsPerRow(): LiedeoCardsPerRow {
    const width = useWidth();
    switch (width) {
        case 'sm':
            return 2;
        default:
            return 4;
    }
}
