import * as React from 'react';
import { useEffect } from 'react';
import { Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { StyleRules } from '@material-ui/core/styles';
import InlineLinkExternal from '../../components/links/inline-link-external';
import { CONTACT_EMAIL_ADDRESS } from '../../util/content';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        content: {
            paddingTop: theme.spacing(4),
        },
    }),
);

export const LegalNotice = (): JSX.Element => {
    const classes = useStyles();

    useEffect((): void => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className={ classes.content }>
            <Typography component='div'>
                <Typography variant="h5">
                    Legal Notice
                </Typography>
                <p>
                    Mixey is brought to you by
                </p>
                <p>
                    <span style={{ display: 'block'}}>
                        MXY Services GbR
                    </span>
                    <span style={{ display: 'block'}}>
                        Siegmund-Schacky-Str. 18e
                    </span>
                    <span style={{ display: 'block'}}>
                        80993 München
                    </span>
                </p>
                <p>
                    <span style={{ display: 'block'}}>
                        Phone: +49 152 5524 0927
                    </span>
                    <span style={{ display: 'block'}}>
                        E-Mail: <a href={`mailto:${CONTACT_EMAIL_ADDRESS}?subject=Hello`}>{CONTACT_EMAIL_ADDRESS}</a>
                    </span>
                </p>
                <p>
                    <span style={{ display: 'block', fontWeight: 'bold'}}>
                        Represented by
                    </span>
                    <span style={{ display: 'block'}}>
                        Fabian Dietenberger
                    </span>
                    <span style={{ display: 'block'}}>
                        Michael Halder
                    </span>
                </p>
                <p>
                    Platform of the EU Commission regarding online dispute resolution:
                </p>
                <InlineLinkExternal url='https://ec.europa.eu/consumers/odr'>
                    https://ec.europa.eu/consumers/odr
                </InlineLinkExternal>
            </Typography>
        </Container>
    );
}
