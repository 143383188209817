import React from 'react';
import Notification from './index';
import { useRootStore } from '../../data/stores/store-context';
import { observer } from 'mobx-react';

export default observer(function NotificationProvider(): JSX.Element {
    const rootStore = useRootStore();

    // todo: use https://material-ui.com/components/snackbars/#notistack to provide multiple notifications

    return (
        <Notification
            variant={rootStore.notificationStore.variant}
            open={rootStore.notificationStore.open}
            message={rootStore.notificationStore.message}
            onClose={rootStore.notificationStore.close}
            autoHideDurationMillis={rootStore.notificationStore.autoHideDurationMillis}
            additionalAction={rootStore.notificationStore.additionalAction}
        />
    )
});
