import axios, { AxiosResponse } from 'axios';
import { Liedeo, LiedeoHost, LiedeoSave, PageableRequestParams, PageableResponse } from '../../types';
import { DEFAULT_LIEDEOS_PAGE_SIZE } from '../../util/request-helpers';

export function isHostYoutube(host: string): boolean {
    const youtubeHost: LiedeoHost = 'YOUTUBE';

    return Boolean(
        host &&
        host.toUpperCase() === youtubeHost,
    );
}

export function fetchLiedeo(id: number): Promise<Liedeo> {
    return axios.get<Liedeo, AxiosResponse<Liedeo>>(`/liedeos/${ id }`)
        .then((response): Liedeo => {
            return response.data;
        });
}

export function fetchTopLiedeos(params: PageableRequestParams = { page: 0, size: DEFAULT_LIEDEOS_PAGE_SIZE }): Promise<PageableResponse<Liedeo>> {
    return axios.get<PageableResponse<Liedeo>, AxiosResponse<PageableResponse<Liedeo>>>('/feed/top', { params })
        .then((response): PageableResponse<Liedeo> => {
            return response.data;
        })
        .catch((error): PageableResponse<Liedeo> => {
            console.error('could not fetch top liedeos', error);
            throw error;
        });
}

export function fetchLatestLiedeos(params: PageableRequestParams = { page: 0, size: DEFAULT_LIEDEOS_PAGE_SIZE }): Promise<PageableResponse<Liedeo>> {
    return axios.get<PageableResponse<Liedeo>, AxiosResponse<PageableResponse<Liedeo>>>('/feed/latest', { params })
        .then((response): PageableResponse<Liedeo> => {
            return response.data;
        })
        .catch((error): PageableResponse<Liedeo> => {
            console.error('could not fetch latest liedeos', error);
            throw error;
        });
}

export function fetchRecommendedByMixeyLiedeos(): Promise<Liedeo[]> {
    // todo: implement
    return new Promise((resolve): void => {
        setTimeout((): void => {
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const recommendedLiedeos = require('./mocks/liedeos-recommended-by-mixey.mock.json');
            resolve(recommendedLiedeos as Liedeo[]);
        }, 500);
    });
}

export function saveLiedeo(liedeoSave: LiedeoSave): Promise<Liedeo> {
    return axios.post<LiedeoSave, AxiosResponse<Liedeo>>('/liedeos', liedeoSave)
        .then((response): Liedeo => {
            return response.data;
        });
}
