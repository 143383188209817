import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { PlayerStore } from '../player-store';
import { observer } from 'mobx-react';
import { StyleRules } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        buttonMarginRight: {
            marginRight: theme.spacing(1),
        },
    }),
);

interface PlayerActionsProps {
    playerStore: PlayerStore;
    onTogglePlay: () => void;
    onStop: () => void;
    onSync: () => void;
}

const PlayerActions = observer((props: PlayerActionsProps): JSX.Element => {
    const styles = useStyles();
    const togglePlayText = props.playerStore.isPlaying ?
        'Pause' :
        'Play';

    return (
        <Box display='inline-flex' alignItems='center'>
            <Button
                id='player-toggle-play'
                variant='outlined'
                size='small'
                title='Play or start the mixey'
                className={ styles.buttonMarginRight }
                disabled={ !props.playerStore.isReady }
                onClick={ props.onTogglePlay }>
                { togglePlayText }
            </Button>
            <Button
                id='player-stop'
                variant='outlined'
                size='small'
                title='Stop the mixey'
                className={ styles.buttonMarginRight }
                disabled={ !props.playerStore.isReady }
                onClick={ props.onStop }>
                Stop
            </Button>
            <Button
                id='player-sync'
                variant='outlined'
                size='small'
                title='Live sync video and audio'
                disabled={ !props.playerStore.isReady }
                onClick={ props.onSync }>
                Sync
            </Button>
        </Box>
    );
});

export default PlayerActions;
