import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ButtonLink from '../../../components/links/button-link';
import React from 'react';
import { HomeLiedeoTypes, useHomeStyles } from '../home';

export interface HeaderProps {
    type: HomeLiedeoTypes;
}

export function HomeLiedeosHeader(props: HeaderProps): JSX.Element {
    const styles = useHomeStyles();

    let headline = '';
    let link = '';

    switch (props.type) {
        case 'top':
            headline = 'Top';
            link = '/feed/top';
            break;
        case 'latest':
            headline = 'Latest';
            link = '/feed/latest';
            break;
        case 'favorites':
            headline = 'Favorites';
            link = '/feed/favorites';
            break;

    }

    return (
        <Grid
            container
            className={ styles.header }
            justify={ 'space-between' }
            alignItems={ 'center' }
        >
            <Typography
                variant={ 'h5' }
                component={ 'h3' }
                display={ 'inline' }
            >
                { headline }
            </Typography>
            <ButtonLink
                to={ link }
                color={ 'primary' }
            >
                See all
            </ButtonLink>
        </Grid>
    );
}
