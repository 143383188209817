import { Liedeo, PageableRequestParams, PageableResponse } from '../../types';
import axios, { AxiosResponse } from 'axios';
import { DEFAULT_LIEDEOS_PAGE_SIZE } from '../../util/request-helpers';

export function fetchFavoriteLiedeos(params: PageableRequestParams = {
    page: 0,
    size: DEFAULT_LIEDEOS_PAGE_SIZE,
}): Promise<PageableResponse<Liedeo>> {
    return axios.get<PageableResponse<Liedeo>, AxiosResponse<PageableResponse<Liedeo>>>('/favorites', { params })
        .then((response): PageableResponse<Liedeo> => {
            return response.data;
        })
        .catch((error): PageableResponse<Liedeo> => {
            console.error('could not fetch favorite liedeos', error);
            throw error;
        });
}

export function saveFavoriteLiedeo(liedeoId: number): Promise<void> {
    return axios.post(`/favorites/${ liedeoId }`);
}

export function deleteFavoriteLiedeo(liedeoId: number): Promise<void> {
    return axios.delete(`/favorites/${ liedeoId }`);
}
