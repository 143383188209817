import React, { useRef } from 'react';
import { Liedeo } from '../../types';
import { createStyles, Dialog, makeStyles, Theme } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import { StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { handleOnFocusSelectAll } from '../../util/event-handler';

const useStyles = makeStyles((theme: Theme): StyleRules =>
    createStyles({
        root: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        shareInput: {
            marginBottom: theme.spacing(3),
        },
        shareButtonContainer: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-around',
        },
        shareButton: {
            display: 'inline-block',
            marginBottom: theme.spacing(2),
            cursor: 'pointer',
            '& * svg': {
                paddingBottom: theme.spacing(1),
            },
            '&:not(:last-of-type)': {
                marginRight: theme.spacing(2),
            },
        },
    }),
);

interface ShareDialogProps {
    open: boolean;
    liedeo: Liedeo;

    onClose(): void;
}

export default function ShareDialog(props: ShareDialogProps): JSX.Element {
    const styles = useStyles();
    const urlInputRef = useRef<HTMLInputElement>(null);

    const handleClose = (): void => {
        props.onClose();
    };

    const handleOnClickCopy = (): void => {
        urlInputRef.current!.select();
        document.execCommand('copy');
    };

    const iconSize = 58;
    const url = window.location.href;
    const title = `Check this out: "${ props.liedeo.title }" - Is this mashup a top 😍 or flop 🤨?`;

    return (
        <Dialog onClose={ handleClose } aria-labelledby="simple-dialog-title" open={ props.open }>
            <DialogTitle id="simple-dialog-title">
                Share
                <IconButton aria-label="close" className={ styles.closeButton } onClick={ handleClose }>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <Box className={ styles.root }>
                <TextField
                    variant='outlined'
                    inputRef={ urlInputRef }
                    className={ styles.shareInput }
                    value={ url }
                    onFocus={ handleOnFocusSelectAll }
                    fullWidth={ true }
                    InputProps={ {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button onClick={ handleOnClickCopy }>
                                    Copy
                                </Button>
                            </InputAdornment>
                        ),
                    } }
                />
                <Box className={ styles.shareButtonContainer }>
                    <Box className={ styles.shareButton }>
                        <FacebookShareButton url={ url } quote={ title } hashtag='#mixey'>
                            <FacebookIcon size={ iconSize } round={ true }/>
                            <Typography display='block' align='center' variant='caption'>
                                Facebook
                            </Typography>
                        </FacebookShareButton>
                    </Box>
                    <Box className={ styles.shareButton }>
                        <TwitterShareButton url={ url } title={ title }>
                            <TwitterIcon size={ iconSize } round={ true }/>
                            <Typography display='block' align='center' variant='caption'>
                                Twitter
                            </Typography>
                        </TwitterShareButton>
                    </Box>
                    <Box className={ styles.shareButton }>
                        <WhatsappShareButton url={ url } title={ title }>
                            <WhatsappIcon size={ iconSize } round={ true }/>
                            <Typography display='block' align='center' variant='caption'>
                                Whatsapp
                            </Typography>
                        </WhatsappShareButton>
                    </Box>
                    <Box className={ styles.shareButton }>
                        <RedditShareButton url={ url } title={ title }>
                            <RedditIcon size={ iconSize } round={ true }/>
                            <Typography display='block' align='center' variant='caption'>
                                Reddit
                            </Typography>
                        </RedditShareButton>
                    </Box>
                    <Box className={ styles.shareButton }>
                        <LinkedinShareButton url={ url }>
                            <LinkedinIcon size={ iconSize } round={ true }/>
                            <Typography display='block' align='center' variant='caption'>
                                LinkedIn
                            </Typography>
                        </LinkedinShareButton>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}
